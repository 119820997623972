import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";

import {
  MachinesDocument,
  MachinesQuery,
  MachineUpdateDocument,
  MachinesQueryVariables,
  MachineUpdateMutationVariables,
  MachineUpdateMutation,
  MachineCreateMutation,
  MachineCreateMutationVariables,
  MachineCreateDocument,
  MachineDiscardMutation,
  MachineDiscardMutationVariables,
  MachineDiscardDocument,
  MachineQuery,
  MachineQueryVariables,
  MachineDocument,
  FuelTypesQuery,
  FuelTypesDocument,
  SortDirection,
  FuelTypesQueryVariables,
  MachineDestroyDocument,
  MachineDestroyMutation,
  MachineDestroyMutationVariables,
} from "../../graphql";
import { useIntl } from "react-intl";
import { merge } from "lodash";
import { formatMachineOption } from "../../formats";

export function useFuelTypeFormat() {
  const intl = useIntl();
  return (type: string) =>
    intl.formatMessage({ id: `machines.fuelTypes.${type}` });
}

export function useFuelTypeOptions() {
  const formatFuelType = useFuelTypeFormat();

  const [load, { data, loading }] = useLazyQuery<
    FuelTypesQuery,
    FuelTypesQueryVariables
  >(FuelTypesDocument);

  const options =
    data?.__type?.enumValues
      ?.map((t) => ({
        key: t.name,
        label: formatFuelType(t.name),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  return { load, loading, options };
}

export function useMachineOptions(
  queryOptions?: QueryHookOptions<MachinesQuery, MachinesQueryVariables>
) {
  const [load, { loading, data }] = useLazyQuery(
    MachinesDocument,
    merge({ variables: { sort: { name: SortDirection.Asc } } }, queryOptions)
  );

  const options = data?.machines?.items?.map((m) => ({
    key: m.id,
    label: m.name,
    children: formatMachineOption(m),
    group: m.kind.name,
    category: m.kind.category,
    machine: m,
  }));

  const search = (val?: string) =>
    load(
      merge({ variables: { sort: undefined }, pageSize: 75 }, queryOptions, {
        variables: {
          filter: {
            name: { match: val },
          },
        },
      })
    );

  return {
    load,
    search,
    loading,
    options,
  };
}

export function useMachines(
  options?: QueryHookOptions<MachinesQuery, MachinesQueryVariables>
) {
  const { loading, data, refetch } = useQuery(MachinesDocument, options);

  if (!data || !data.machines || !data.machines.items) {
    return { loading };
  }

  const items = data.machines.items;
  const totalCount = data.machines.totalCount;

  return { loading, items, totalCount, refetch };
}

export function useMachine(id: string) {
  const { data } = useQuery<MachineQuery, MachineQueryVariables>(
    MachineDocument,
    { variables: { id } }
  );

  return data && data.machine;
}

export function useMachineUpdate(
  options?: MutationHookOptions<
    MachineUpdateMutation,
    MachineUpdateMutationVariables
  >
) {
  return useMutation(MachineUpdateDocument, options);
}

export function useMachineCreate(
  options?: MutationHookOptions<
    MachineCreateMutation,
    MachineCreateMutationVariables
  >
) {
  return useMutation(MachineCreateDocument, options);
}

export function useMachineDiscard(
  options?: MutationHookOptions<
    MachineDiscardMutation,
    MachineDiscardMutationVariables
  >
) {
  return useMutation(MachineDiscardDocument, options);
}

export function useMachineDestroy(
  options?: MutationHookOptions<
    MachineDestroyMutation,
    MachineDestroyMutationVariables
  >
) {
  return useMutation(MachineDestroyDocument, options);
}
