import {
  CurrentTenantFragment,
  CurrentUser,
  Permissions,
  PermissionsInput,
} from "./graphql";
import { MessageDescriptor, defineMessages } from "react-intl";
import routes from "./routes";
import { MenuIcons } from "./images";
import { omitTypename } from "./apollo/init";
import { reports } from "../pages/reports";
import { canAccessMap } from "../pages/localities/map";

interface ModuleConfig {
  key: string;
  title: MessageDescriptor;
  icon: (typeof MenuIcons)[keyof typeof MenuIcons];
}

type MenuType = "left" | "top";

export interface PageConfig {
  title: MessageDescriptor;
  module: ModuleConfig;
  route: string;
  menus: Array<MenuType>;
  description?: MessageDescriptor;
  backendClass?: string;
  rwdPermission?: keyof PermissionsInput;
  sensitive?: boolean;
  visible(
    permissions: Permissions,
    currentTenant: CurrentTenantFragment
  ): boolean | undefined;
}

const messages = defineMessages({
  categories: { id: "categories", defaultMessage: "categories" },
  transfers: { id: "transfers", defaultMessage: "transfers" },
  adjustments: { id: "adjustments", defaultMessage: "adjustments" },
  adjustmentTypes: { id: "adjustmentTypes", defaultMessage: "adjustmentTypes" },
  purchases: { id: "purchases", defaultMessage: "purchases" },
  sales: { id: "sales", defaultMessage: "Sales" },
  groups: { id: "groups", defaultMessage: "groups" },
  requests: { id: "requests", defaultMessage: "requests" },
  activeIngredients: {
    id: "activeIngredients",
    defaultMessage: "activeIngredients",
  },
  chart: { id: "accounts.chart", defaultMessage: "chart" },
  activities: { id: "activities", defaultMessage: "activities" },
  administration: { id: "administration", defaultMessage: "administration" },
  agriculture: { id: "agriculture", defaultMessage: "agriculture" },
  inventory: { id: "inventory", defaultMessage: "inventory" },
  infrastructure: { id: "infrastructure", defaultMessage: "infrastructure" },
  fleet: { id: "fleet", defaultMessage: "fleet" },
  hr: { id: "hr", defaultMessage: "hr" },
  finance: { id: "finance", defaultMessage: "finance" },
  reports: { id: "reports", defaultMessage: "reports" },
  services: { id: "services", defaultMessage: "services" },
  expenseCategories: {
    id: "expenseCategories",
    defaultMessage: "Expense Categories",
  },
  expenseItems: {
    id: "expenseItems",
    defaultMessage: "Expense Items",
  },
  salaryComponents: {
    id: "salaryComponents",
    defaultMessage: "Salary Components",
  },
  salaryStructures: {
    id: "salaryStructures",
    defaultMessage: "Salary Structures",
  },
  payrollEntries: {
    id: "payrollEntries",
    defaultMessage: "Payroll Entries",
  },
  financeOrders: { id: "financeOrders", defaultMessage: "financeOrders" },
  financeInvoices: { id: "invoices", defaultMessage: "invoices" },
  tokens: { id: "tokens", defaultMessage: "tokens" },
  fiscalYears: { id: "fiscalYears", defaultMessage: "fiscalYears" },
  holidays: {
    id: "hr.holidays.pageTitle",
    defaultMessage: "Holidays",
  },
  leaveAssignments: {
    id: "leaveAssignments.abbreviated",
    defaultMessage: "Leave Assignments",
  },
  harvestEstimates: {
    id: "harvestEstimates",
  },
  weather: {
    id: "weather",
    defaultMessage: "Weather",
  },
  farmMap: {
    id: "localities.map",
    defaultMessage: "Farm map",
  },
});

export const modules = {
  farmMap: {
    key: "farmMap",
    title: messages.farmMap,
    icon: MenuIcons.MapOutline,
  },
  activities: {
    key: "activities",
    title: messages.activities,
    icon: MenuIcons.PlanningIcon,
  },
  administration: {
    key: "administration",
    title: messages.administration,
    icon: MenuIcons.PlanningIcon,
  },
  agriculture: {
    key: "agriculture",
    title: messages.agriculture,
    icon: MenuIcons.AgricultureIcon,
  },
  inventory: {
    key: "inventory",
    title: messages.inventory,
    icon: MenuIcons.InventoryIcon,
  },
  infrastructure: {
    key: "infrastructure",
    title: messages.infrastructure,
    icon: MenuIcons.InfrastructureIcon,
  },
  fleet: {
    key: "fleet",
    title: messages.fleet,
    icon: MenuIcons.MachineryIcon,
  },
  hr: {
    key: "hr",
    title: messages.hr,
    icon: MenuIcons.HrIcon,
  },
  finance: {
    key: "finance",
    title: messages.finance,
    icon: MenuIcons.FinanceIcon,
  },
  reports: {
    key: "reports",
    title: messages.reports,
    icon: MenuIcons.ReportsIcon,
  },
  settings: {
    key: "settings",
    title: { id: "companies.settings" },
    icon: MenuIcons.PlanningIcon,
  },
};

export const pages: Array<PageConfig> = [
  {
    title: { id: "tenants", defaultMessage: "tenants" },
    module: modules.administration,
    route: routes.tenants.index,
    menus: ["top"],
    visible: (p) => p.tenant?.write,
  },
  {
    title: { id: "users", defaultMessage: "users" },
    module: modules.administration,
    route: routes.users.index,
    menus: ["top"],
    rwdPermission: "user",
    sensitive: true,
    visible: (p) => p.user?.read,
  },
  {
    title: { id: "roles", defaultMessage: "roles" },
    module: modules.administration,
    route: routes.roles.index,
    menus: ["top"],
    rwdPermission: "role",
    sensitive: true,
    visible: (p) => p.role?.read,
  },
  {
    title: { id: "units", defaultMessage: "units" },
    module: modules.administration,
    route: routes.units.index,
    menus: ["top"],
    backendClass: "Unit",
    rwdPermission: "unit",
    visible: (p) => p.unit?.read,
  },
  {
    title: { id: "localities", defaultMessage: "localities" },
    module: modules.administration,
    route: routes.localities.index,
    menus: ["top"],
    backendClass: "Locality",
    rwdPermission: "locality",
    sensitive: true,
    visible: (p) => p.locality?.read,
  },
  {
    title: { id: "imports", defaultMessage: "imports" },
    module: modules.administration,
    route: routes.imports.index,
    menus: ["top"],
    visible: (p) => p.settings?.import,
  },
  {
    title: { id: "stats", defaultMessage: "Stats" },
    module: modules.administration,
    route: routes.stats.tenants,
    menus: ["top"],
    visible: (p) => p.tenant?.read,
  },
  {
    title: { id: "tenants.testMode.reset", defaultMessage: "Reset Test Data" },
    module: modules.administration,
    route: routes.tenants.settings.testMode,
    menus: ["top"],
    visible: (p, t) => p.settings?.resetTestMode && !!t.liveTenantId,
  },
  {
    title: { id: "localities.map", defaultMessage: "Farm map" },
    module: modules.farmMap,
    route: routes.localities.map,
    menus: ["left"],
    visible: canAccessMap,
  },
  {
    title: { id: "activities", defaultMessage: "activities" },
    module: modules.activities,
    route: routes.agro.activities.index,
    menus: ["left", "top"],
    rwdPermission: "activity",
    visible: (p) => p.activity?.read,
  },
  {
    title: { id: "metrics", defaultMessage: "Metrics" },
    module: modules.activities,
    route: routes.agro.metrics.index,
    menus: ["top"],
    rwdPermission: "metric",
    visible: (m) => m.metric?.read,
  },
  {
    title: messages.categories,
    module: modules.activities,
    route: routes.agro.activityCategories.index,
    menus: ["top"],
    backendClass: "Agro::ActivityCategory",
    rwdPermission: "activityCategory",
    visible: (p) => p.activityCategory?.read,
  },
  {
    title: { id: "plannings.entityName", defaultMessage: "Planning" },
    module: modules.activities,
    route: routes.agro.plannings.index,
    menus: ["left", "top"],
    rwdPermission: "planning",
    visible: (p, t) => p.planning?.read && !!t.planningEnabled,
  },
  {
    title: { id: "workOrders", defaultMessage: "workOrders" },
    module: modules.activities,
    route: routes.agro.workOrders.index,
    menus: ["left", "top"],
    rwdPermission: "workOrder",
    visible: (p) => p.workOrder?.read,
  },
  {
    title: messages.activeIngredients,
    module: modules.inventory,
    route: routes.inventory.ingredients.index,
    menus: ["top"],
    visible: (p) => p.ingredient?.read,
  },
  {
    title: { id: "crops", defaultMessage: "crops" },
    module: modules.agriculture,
    route: routes.agro.crops.index,
    menus: ["top"],
    backendClass: "Agro::Crop",
    rwdPermission: "crop",
    visible: (p) => p.crop?.read,
  },
  {
    title: { id: "cropVarieties" },
    module: modules.agriculture,
    route: routes.agro.cropVarieties.index,
    menus: ["top"],
    backendClass: "Agro::CropVariety",
    visible: (p) => p.crop?.read,
  },
  {
    title: { id: "cropFields", defaultMessage: "cropFields" },
    module: modules.agriculture,
    route: routes.agro.cropFields.index,
    menus: ["left", "top"],
    rwdPermission: "cropField",
    visible: (p) => p.cropField?.read,
  },
  {
    title: { id: "cropStages", defaultMessage: "cropStages" },
    module: modules.agriculture,
    route: routes.agro.cropStages.index,
    menus: ["left", "top"],
    backendClass: "Agro::CropStage",
    rwdPermission: "cropStage",
    visible: (p) => p.cropStage?.read,
  },
  {
    title: { id: "cropCycles", defaultMessage: "cropCycles" },
    module: modules.agriculture,
    route: routes.agro.cropCycles.index,
    menus: ["left", "top"],
    rwdPermission: "cropCycle",
    visible: (p) => p.cropCycle?.read,
  },
  {
    title: messages.harvestEstimates,
    module: modules.agriculture,
    route: routes.agro.harvestEstimates.index,
    menus: ["left", "top"],
    rwdPermission: "harvestEstimate",
    visible: (p) => p.harvestEstimate?.read,
  },
  {
    title: messages.weather,
    module: modules.agriculture,
    route: routes.agro.weatherMeasurements.index,
    menus: ["top", "left"],
    rwdPermission: "weatherMeasurement",
    visible: (p) => p.weatherMeasurement?.read,
  },
  {
    title: { id: "diseases", defaultMessage: "diseases" },
    module: modules.agriculture,
    route: routes.agro.diseases.index,
    menus: ["top", "left"],
    rwdPermission: "disease",
    visible: (p) => p.disease?.read,
  },

  {
    title: messages.requests,
    module: modules.inventory,
    route: routes.agro.inventoryRequests.index,
    menus: ["left", "top"],
    rwdPermission: "inventoryRequest",
    visible: (p, t) => p.inventoryRequest?.read && !t.inventoryRequestDisabled,
  },
  {
    title: { id: "itemVariants", defaultMessage: "itemVariants" },
    module: modules.inventory,
    route: routes.items.index,
    menus: ["left", "top"],
    rwdPermission: "item",
    visible: (p) => p.item?.read,
  },
  {
    title: messages.categories,
    module: modules.inventory,
    route: routes.itemCategories.index,
    menus: ["top"],
    backendClass: "Inventory::ItemCategory",
    rwdPermission: "itemCategory",
    visible: (p) => p.itemCategory?.read,
  },
  {
    title: messages.transfers,
    module: modules.inventory,
    route: routes.inventory.transfers.index,
    menus: ["left", "top"],
    rwdPermission: "transfer",
    visible: (p) => p.transfer?.read,
  },
  {
    title: messages.adjustments,
    module: modules.inventory,
    route: routes.inventory.adjustments.index,
    menus: ["left", "top"],
    visible: (p) => p.transfer?.read,
  },
  {
    title: messages.adjustmentTypes,
    module: modules.inventory,
    route: routes.inventory.adjustmentTypes.index,
    menus: ["top"],
    rwdPermission: "adjustmentType",
    visible: (p) => p.adjustmentType?.read,
  },
  {
    title: messages.purchases,
    module: modules.inventory,
    route: routes.inventory.purchases.index,
    menus: ["left", "top"],
    rwdPermission: "purchase",
    visible: (p) => p.purchase?.read,
  },
  {
    title: messages.sales,
    module: modules.inventory,
    route: routes.inventory.sales.index,
    menus: ["left", "top"],
    rwdPermission: "sale",
    visible: (p) => p.sale?.read,
  },

  {
    title: { id: "warehouses", defaultMessage: "warehouses" },
    module: modules.infrastructure,
    route: routes.warehouses.index,
    menus: ["left", "top"],
    visible: (p) => p.infrastructure?.read,
  },
  {
    title: { id: "infrastructures.processingPlants.menuItem" },
    module: modules.infrastructure,
    route: routes.processingPlants.index,
    menus: ["left", "top"],
    visible: (p) => p.infrastructure?.read,
  },
  {
    title: { id: "infrastructures.buildings" },
    module: modules.infrastructure,
    route: routes.buildings.index,
    menus: ["left", "top"],
    visible: (p) => p.infrastructure?.read,
  },
  {
    title: { id: "infrastructures.grids" },
    module: modules.infrastructure,
    route: routes.grids.index,
    menus: ["left", "top"],
    visible: (p) => p.infrastructure?.read,
  },

  {
    title: { id: "fleet", defaultMessage: "fleet" },
    module: modules.fleet,
    route: routes.machines.index,
    menus: ["left", "top"],
    rwdPermission: "machine",
    visible: (p) => p.machine?.read,
  },
  {
    title: { id: "machineKinds", defaultMessage: "machineKinds" },
    module: modules.fleet,
    route: routes.machineKinds.index,
    menus: ["top"],
    backendClass: "MachineKind",
    visible: (p) => p.machineKind?.read,
  },
  {
    title: { id: "brands", defaultMessage: "brands" },
    module: modules.fleet,
    route: routes.brands.index,
    menus: ["top"],
    backendClass: "Brand",
    rwdPermission: "brand",
    visible: (p) => p.brand?.read,
  },
  {
    title: messages.leaveAssignments,
    module: modules.hr,
    route: routes.hr.leaveAssignments.index,
    menus: ["left", "top"],
    rwdPermission: "leaveAssignment",
    visible: (p) => p.leaveAssignment?.read,
  },
  {
    title: { id: "employees", defaultMessage: "employees" },
    module: modules.hr,
    route: routes.employees.index,
    menus: ["left", "top"],
    rwdPermission: "employee",
    visible: (p) => p.employee?.read,
  },
  {
    title: { id: "positions", defaultMessage: "positions" },
    module: modules.hr,
    route: routes.positions.index,
    menus: ["top"],
    backendClass: "Position",
    rwdPermission: "position",
    visible: (p) => p.position?.read,
  },
  {
    title: { id: "leaveTypes", defaultMessage: "leaveTypes" },
    module: modules.hr,
    route: routes.leaveTypes.index,
    menus: ["top"],
    rwdPermission: "leaveType",
    visible: (p) => p.leaveType?.read,
  },
  {
    title: messages.groups,
    module: modules.hr,
    route: routes.employeeGroups.index,
    menus: ["left", "top"],
    rwdPermission: "employeeGroup",
    visible: (p) => p.employeeGroup?.read,
  },
  {
    title: messages.holidays,
    module: modules.hr,
    route: routes.hr.holidays.index,
    menus: ["top"],
    rwdPermission: "holiday",
    visible: (p) => p.holiday?.read,
  },
  {
    title: messages.salaryComponents,
    module: modules.hr,
    route: routes.hr.salaryComponents.index,
    menus: ["top"],
    visible: (p, t) => p.salaryStructure?.write && !!t.salaryStructureEnabled,
  },
  {
    title: messages.salaryStructures,
    module: modules.hr,
    route: routes.hr.salaryStructures.index,
    rwdPermission: "salaryStructure",
    menus: ["left", "top"],
    visible: (p, t) => p.salaryStructure?.write && !!t.salaryStructureEnabled,
  },
  {
    title: messages.payrollEntries,
    module: modules.hr,
    route: routes.hr.payrollEntries.index,
    rwdPermission: "payrollEntry",
    menus: ["left", "top"],
    visible: (p, t) => p.payrollEntry?.write && !!t.salaryStructureEnabled,
  },
  {
    title: messages.fiscalYears,
    module: modules.finance,
    route: routes.finance.fiscalYears.index,
    menus: ["left", "top"],
    rwdPermission: "fiscalYear",
    visible: (p) => p.fiscalYear?.read,
  },
  {
    title: messages.chart,
    module: modules.finance,
    route: routes.finance.accounts.index,
    menus: ["left", "top"],
    rwdPermission: "account",
    visible: (p, t) => !t.accountingDisabled && p.account?.read,
  },
  {
    title: { id: "costCenters", defaultMessage: "costCenters" },
    module: modules.finance,
    route: routes.finance.costCenters.index,
    menus: ["left", "top"],
    rwdPermission: "costCenter",
    visible: (p) => p.costCenter?.read,
  },
  {
    title: { id: "paymentMethods", defaultMessage: "paymentMethods" },
    module: modules.finance,
    route: routes.paymentMethods.index,
    menus: ["top"],
    rwdPermission: "paymentMethod",
    visible: (p) => p.paymentMethod?.read,
  },
  {
    title: { id: "taxComponents", defaultMessage: "Tax Components" },
    module: modules.finance,
    route: routes.finance.taxComponents.index,
    menus: ["top"],
    rwdPermission: "taxPlan",
    visible: (p, t) => !!t.taxPlanEnabled && p.taxPlan?.read,
  },
  {
    title: { id: "taxPlans", defaultMessage: "Tax Plans" },
    module: modules.finance,
    route: routes.finance.taxPlans.index,
    menus: ["top"],
    rwdPermission: "taxPlan",
    visible: (p, t) => !!t.taxPlanEnabled && p.taxPlan?.read,
  },
  {
    title: { id: "counterparties", defaultMessage: "counterparties" },
    module: modules.finance,
    route: routes.counterparties.index,
    menus: ["left", "top"],
    rwdPermission: "counterparty",
    visible: (p) => p.counterparty?.read,
  },
  {
    title: { id: "journalEntries", defaultMessage: "journalEntries" },
    module: modules.finance,
    route: routes.finance.journalEntries.index,
    menus: ["left", "top"],
    rwdPermission: "journalEntry",
    visible: (p, t) =>
      !t.accountingDisabled &&
      !t.financeEntriesDisabled &&
      p.journalEntry?.read,
  },
  {
    title: messages.expenseCategories,
    module: modules.finance,
    route: routes.finance.expenseCategories.index,
    menus: ["top"],
    backendClass: "Finance::ExpenseCategory",
    rwdPermission: "expenseCategory",
    visible: (p) => p.expenseCategory?.read,
  },
  {
    title: messages.expenseItems,
    module: modules.finance,
    route: routes.finance.expenseItems.index,
    menus: ["left", "top"],
    backendClass: "Finance::ExpenseItem",
    rwdPermission: "expenseItem",
    visible: (p) => p.expenseItem?.read,
  },
  {
    title: { id: "expenseOrders", defaultMessage: "Expense Orders" },
    module: modules.finance,
    route: routes.finance.expenseOrders.index,
    menus: ["left", "top"],
    rwdPermission: "financeOrder",
    visible: (p) => p.financeOrder?.read,
  },
  {
    title: { id: "invoices", defaultMessage: "invoices" },
    module: modules.finance,
    route: routes.finance.invoices.index,
    menus: ["left", "top"],
    rwdPermission: "financeInvoice",
    visible: (p) => p.financeInvoice?.read,
  },
  {
    title: { id: "tokens", defaultMessage: "tokens" },
    module: modules.finance,
    route: routes.finance.tokens.index,
    menus: ["top"],
    visible: (p, t) => p.workOrder?.read && !!t.allowTokens,
  },
  {
    title: messages.reports,
    module: modules.reports,
    route: routes.reports.index,
    menus: ["left"],
    visible: (p) =>
      !!p.report && Object.values(omitTypename(p.report)).some((r) => r),
  },
  {
    title: { id: "basicInfo" },
    module: modules.settings,
    route: routes.tenants.settings.basic.index,
    menus: ["top"],
    visible: (p) => p.settings?.company,
  },
  {
    title: { id: "inventory" },
    module: modules.settings,
    route: routes.tenants.settings.inventory,
    menus: ["top"],
    visible: (p) => p.settings?.company,
  },
  {
    title: { id: "company.settings.accounting" },
    module: modules.settings,
    route: routes.tenants.settings.accounting,
    menus: ["top"],
    visible: (p) => p.settings?.company,
  },
  {
    title: { id: "hr" },
    module: modules.settings,
    route: routes.tenants.settings.hr,
    menus: ["top"],
    visible: (p) => p.settings?.company,
  },
  {
    title: { id: "billing" },
    module: modules.settings,
    route: routes.tenants.settings.billing,
    menus: ["top"],
    visible: (_, t) => t.canBilling.value,
  },
];

export function visibleModules(user: CurrentUser, menu?: MenuType) {
  return pages
    .concat(
      reports.map(({ group, ...r }) => ({
        ...r,
        menus: ["top"],
        module: modules.reports,
      }))
    )
    .reduce((prev, cur) => {
      if (
        (!menu || cur.menus.includes(menu)) &&
        cur.visible(user.permissions, user.currentTenant)
      ) {
        prev[cur.module.key] = [...(prev[cur.module.key] || []), cur];
      }
      return prev;
    }, {} as Record<string, PageConfig[]>);
}
