import React, { ReactNode, ReactElement } from "react";
import {
  SelectFieldProps,
  AutoSubmit,
  SelectField,
  DatePicker,
  FormProps,
} from "../../form";
import { Form, Input, Switch, InputNumber } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useDateRangeTypeOptions } from "../../../lib/hooks";
import { Store } from "antd/lib/form/interface";

export interface FilterProps<T> extends SelectFieldProps {
  title?: ReactNode;
  name: Extract<keyof T, string>;
  type:
    | "string"
    | "select"
    | "number"
    | "date"
    | "switch"
    | "custom"
    | "search";
  multiple?: boolean;
  initialValue?: any;
  render?(): ReactElement;
}

interface FilterConfig<T> {
  entityName?: string;
  filters: FilterProps<T>[];
  formProps?: FormProps<any>;
  initialValues: T;
  onChange(values: T): void;
}

function isRangeType(rangeType: any) {
  return rangeType === "range" || rangeType === "monthRange";
}

export function FilterPanel<T>({ formProps, ...props }: FilterConfig<T>) {
  const [form] = Form.useForm(formProps?.form);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={props.initialValues as Store}
      onFinish={(values) => {
        props.filters.forEach((f) => {
          const val = values[f.name];
          if (
            !val ||
            (Array.isArray(val) && val.length === 0) ||
            (val.rangeType !== undefined && val.rangeType == null) ||
            (isRangeType(val.rangeType) && val.range == undefined)
          )
            delete values[f.name];
        });
        props.onChange(values as T);
      }}
    >
      {props.filters.map((filter) => {
        let input: ReactElement | null = null;
        switch (filter.type) {
          case "search":
          case "string":
            input = filter.multiple ? (
              <SelectField
                mode="multiple"
                onSearch={filter.onSearch}
                options={filter.options}
                showGroups={filter.showGroups}
                optionsHook={filter.optionsHook}
                optionsHookParams={filter.optionsHookParams}
                optionsHookFilter={filter.optionsHookFilter}
              />
            ) : (
              <Input.Search name={filter.name} allowClear={true} />
            );
            break;
          case "select":
            input = (
              <SelectField
                mode={filter.multiple ? "multiple" : undefined}
                onSearch={filter.onSearch}
                options={filter.options}
                showGroups={filter.showGroups}
                loading={filter.loading}
                optionsHook={filter.optionsHook}
                optionsHookParams={filter.optionsHookParams}
                optionsHookFilter={filter.optionsHookFilter}
              />
            );
            break;
          case "date": {
            const fieldName = [filter.name, "rangeType"];
            input = (
              <>
                <Form.Item noStyle name={fieldName}>
                  <SelectField
                    optionsHook={useDateRangeTypeOptions}
                    showSearch={false}
                    onChange={(value) => {
                      if (!isRangeType(value)) {
                        form.setFields([
                          { name: [filter.name, "range"], value: undefined },
                        ]);
                      }
                    }}
                  />
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const rangeType = getFieldValue(fieldName);
                    if (!isRangeType(rangeType)) return null;

                    return (
                      <Form.Item noStyle name={[filter.name, "range"]}>
                        <DatePicker.RangePicker
                          picker={rangeType === "monthRange" ? "month" : "date"}
                          style={{ marginTop: "5px", width: "100%" }}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </>
            );

            break;
          }
          case "switch":
            input = (
              <Switch
                unCheckedChildren={<EyeInvisibleOutlined />}
                checkedChildren={<EyeOutlined />}
              />
            );
            break;
          case "number":
            input = <InputNumber style={{ width: "100%" }} />;
            break;
          case "custom":
            if (filter.render) {
              input = filter.render();
            }
            break;
        }

        let label = filter.title;

        if (!label && filter.name === "name") label = props?.entityName;

        return (
          input && (
            <Form.Item
              key={filter.name}
              name={filter.name}
              label={label}
              valuePropName={filter.type === "switch" ? "checked" : undefined}
              noStyle={!label}
            >
              {input}
            </Form.Item>
          )
        );
      })}
      <AutoSubmit />
    </Form>
  );
}
