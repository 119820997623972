import React, { createContext, useState, useEffect, FC } from "react";
import { useCookies } from "react-cookie";
import {
  CurrentUser,
  PermissionsFragment,
  CurrentUserFragment,
} from "../../../lib/graphql";
import { useMe, useMediaXs } from "../../../lib/hooks";
import * as Sentry from "@sentry/nextjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Router, { useRouter } from "next/router";
import routes, { resetQueryParams } from "../../../lib/routes";
import { LocaleProvider } from "./LocaleContext";
import { ILocalityState, useLocalityStateReducer } from "./reducer";
import { useHubspot } from "../../../lib/hooks/hubspot";
import { useIdentifyMicrosoftClarity } from "../../../lib/hooks/microsoft-clarity";

dayjs.extend(utc);
dayjs.extend(timezone);

const COOKIE_PREFIX = process.env.COOKIE_PREFIX || "_ara";
// it's defined here and not in LocaleContext because it throws ReferenceError: Cannot access 'COOKIE_PREFIX' before initialization
export const LOCALE_COOKIE = `${COOKIE_PREFIX}_locale`;
const TENANT_COOKIE = `${COOKIE_PREFIX}_tenant`;
export const LOCALITY_COOKIE = `${COOKIE_PREFIX}_locality`;
export const CROP_CYCLE_COOKIE = `${COOKIE_PREFIX}_crop_cycle`;
const COLLAPSED_COOKIE = `${COOKIE_PREFIX}_cllpsd`;
const COOKIE_MAX_AGE = 60 * 60 * 24 * 1000;

export const DefaultCookieOptions = {
  domain: process.env.DOMAIN,
  maxAge: COOKIE_MAX_AGE,
  path: "/",
};

export type AuthorizeFnType = (p?: PermissionsFragment) => boolean;

interface UserContextType {
  user?: CurrentUser;
  currentTenant: CurrentUserFragment["currentTenant"];
  currentLocality?: ILocalityState["locality"];
  currentLocalityId?: string;
  currentLocalityIds?: string[];
  localities: ILocalityState["localities"];
  currentCropCycle?: ILocalityState["cropCycle"];
  currentCropCycleId?: string;
  currentCropCycleIds?: string[];
  cropCycles?: ILocalityState["cropCycles"];
  menuCollapsed: boolean;
  forceOnboarding: boolean;
  setCurrentTenant(tenantId: string, redirectTo?: string): void;
  setMenuCollapsed(collapsed: boolean): void;
  setCurrentLocality(localityId?: string): void;
  useAuthorized: (
    authorize?: AuthorizeFnType,
    options?: { skipRedirect?: boolean }
  ) => {
    user?: CurrentUserFragment | null;
  };
  setCurrentCropCycle(cropCycleId?: string): void;
  refetch(): void;
}

export const UserContext = createContext<UserContextType>({
  menuCollapsed: false,
  currentTenant: {} as CurrentUserFragment["currentTenant"],
  localities: [],
  forceOnboarding: false,
  setMenuCollapsed: () => {},
  setCurrentTenant: () => {},
  setCurrentLocality: () => {},
  useAuthorized: () => ({}),
  setCurrentCropCycle: () => {},
  refetch: () => {},
});

function useMenu() {
  const [cookies, setCookie] = useCookies();
  const isXs = useMediaXs();

  const [menuCollapsed, setCollapsed] = useState(
    cookies[COLLAPSED_COOKIE] === "true" || isXs
  );

  const setMenuCollapsed = (collapsed: boolean) => {
    setCookie(COLLAPSED_COOKIE, collapsed, DefaultCookieOptions);
    setCollapsed(collapsed);
  };

  return { menuCollapsed, setMenuCollapsed };
}

const LoadedUserContext: FC<
  React.PropsWithChildren<{
    language: string;
    user: CurrentUserFragment;
    refetchUser: UserContextType["refetch"];
  }>
> = ({ user, language, children, refetchUser }) => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const currentTenant = user.currentTenant;
  const currentLocale = user.currentLocale || cookies[LOCALE_COOKIE] || "es";

  const {
    locality,
    localities,
    setCurrentLocality,
    cropCycle,
    cropCycles,
    setCurrentCropCycle,
  } = useLocalityStateReducer({
    tenant: currentTenant,
    localityId: cookies[LOCALITY_COOKIE],
    cropCycleId: cookies[CROP_CYCLE_COOKIE],
  });

  useEffect(() => {
    Sentry.setContext("context", {
      tenant: currentTenant.name,
      locality: locality?.name,
      locale: currentLocale,
    });
    Sentry.setTag("tenant", currentTenant.name);
    dayjs.locale(currentLocale);
  }, [currentTenant.name, locality?.name, currentLocale]);

  useEffect(() => {
    Sentry.setUser({
      id: user.id,
      email: user.email,
      username: user.username || undefined,
    });
  }, [user]);

  useHubspot({ user, currentTenant });

  useEffect(() => {
    dayjs.tz.setDefault(currentTenant.timeZone); // set timezone for antd pickers
  }, [currentTenant.timeZone]);

  useIdentifyMicrosoftClarity(user);

  const resetCurrentTenant = (tenantId: string, redirectTo?: string) => {
    setCookie(TENANT_COOKIE, tenantId, DefaultCookieOptions);
    removeCookie(LOCALITY_COOKIE, DefaultCookieOptions);
    removeCookie(CROP_CYCLE_COOKIE, DefaultCookieOptions);
    if (
      redirectTo ||
      Router.query.id ||
      Router.route == routes.tenants.settings.testMode
    ) {
      window.location.assign(redirectTo || routes.dashboard);
    } else {
      Router.reload();
    }
  };

  const forceOnboarding =
    !currentTenant.fullName || currentTenant.localities.length === 0;

  const useAuthorized: UserContextType["useAuthorized"] = (
    authorize,
    options = {}
  ) => {
    const lockedInOnboarding =
      forceOnboarding && Router.pathname != routes.dashboard;

    if (lockedInOnboarding || (authorize && !authorize(user.permissions))) {
      if (!options.skipRedirect) Router.replace(routes.dashboard);
      return { user: undefined };
    }

    return { user };
  };

  return (
    <UserContext.Provider
      value={{
        user: user as CurrentUser,
        currentTenant,
        currentLocality: locality,
        currentLocalityId: locality?.id,
        currentLocalityIds: locality?.id ? [locality?.id] : undefined,
        localities,
        setCurrentLocality: (localityId?: string) => {
          resetQueryParams();
          setCurrentLocality(localityId);
        },
        setCurrentTenant: resetCurrentTenant,
        useAuthorized,
        currentCropCycle: cropCycle,
        currentCropCycleId: cropCycle?.id,
        currentCropCycleIds: cropCycle?.id ? [cropCycle?.id] : undefined,
        cropCycles,
        forceOnboarding,
        setCurrentCropCycle: (cropCycleId?: string) => {
          resetQueryParams();
          setCurrentCropCycle(cropCycleId);
        },
        refetch: refetchUser,
        ...useMenu(),
      }}
    >
      <LocaleProvider
        locale={currentLocale}
        language={
          currentTenant.address?.country?.code == "NI" ? "en-ph" : language
        }
        timezone={currentTenant.timeZone}
      >
        {children}
      </LocaleProvider>
    </UserContext.Provider>
  );
};

const ContextProvider: FC<React.PropsWithChildren<{ skipAuth?: boolean }>> = ({
  children,
  skipAuth,
}) => {
  const router = useRouter();

  const language =
    typeof navigator !== "undefined"
      ? navigator.language.split("-")[0]
      : router.locale || "es";

  const { me, loading, refetch } = useMe();

  if (loading) return null;

  // even if page is public we wrap to user context (e.g. redirect from login page)
  if (me)
    return (
      <LoadedUserContext user={me} language={language} refetchUser={refetch}>
        {children}
      </LoadedUserContext>
    );

  if (skipAuth)
    return <LocaleProvider language={language}>{children}</LocaleProvider>;
  else {
    Router.replace(
      process.env.ARAGRO_ENV === "demo" ? routes.user.signUp : routes.user.login
    );
    return null;
  }
};

export default ContextProvider;
