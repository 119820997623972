import { FormattedMessage } from "react-intl";
import { CurrentTenantFragment, TenantFragment } from "../graphql";
import { formatDateTime } from "./dates";

export function formatTenantName(
  tenant: TenantFragment | CurrentTenantFragment
) {
  return tenant.fullName || tenant.name;
}

export function formatTestDataResetAt(tenant: CurrentTenantFragment) {
  return tenant.resetAt ? (
    <FormattedMessage
      id="tenants.testMode.resetAt"
      defaultMessage="Reset at {date}"
      values={{ date: formatDateTime(tenant.resetAt) }}
    />
  ) : (
    <FormattedMessage
      id="tenants.testMode.createdAt"
      defaultMessage="Created at {date}"
      values={{ date: formatDateTime(tenant.createdAt) }}
    />
  );
}

export function formatSSNLabel(tenant: CurrentTenantFragment) {
  return tenant.address?.country?.ssnName || "SSN";
}

export function formatTaxIDLabel(
  tenant: CurrentTenantFragment | TenantFragment
) {
  return (
    tenant.address?.country?.taxId || (
      <FormattedMessage id="taxId" defaultMessage="Tax ID" />
    )
  );
}

export function formatNationalIDLabel(
  tenant: CurrentTenantFragment | TenantFragment
) {
  return (
    tenant.address?.country?.nationalId || (
      <FormattedMessage id="nationalId" defaultMessage="National ID" />
    )
  );
}
