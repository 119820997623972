// inspiration can be borrowed from https://github.com/Raruto/leaflet-kmz

import { kml } from "@tmcw/togeojson";
import JSZip from "jszip";
import type { GeoFeatures } from "./map";

// Convert KMZ or KML file to GeoJSON
export async function kmzToGeoJson(
  file: File | Blob
): Promise<GeoFeatures | undefined> {
  const isKmz = await isZipped(file);

  if (isKmz) {
    return JSZip.loadAsync(file).then(async (f) => {
      const t = await Object.values(f.files)[0].async("text");
      return kmlToGeoJson(t);
    });
  }

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(kmlToGeoJson(event.target?.result as string));
    };
    reader.readAsText(file);
  });
}

// Convert KML string to GeoJSON with normalization
function kmlToGeoJson(data: string) {
  const xmlData = parseXML(data);
  if (!xmlData) throw new Error("Error parsing XML data");
  const kmlText = kml(xmlData) as GeoFeatures;
  if (!kmlText) return;
  return normalizeGeojson(kmlText);
}

// Parse XML from string with error handling and fixing
function parseXML(data: string) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(fixKML(data), "text/xml");

  const parserErrors = xmlDoc.getElementsByTagName("parsererror");
  if (parserErrors.length > 0) {
    const errors = Array.from(parserErrors).map((error) => error.textContent);
    throw new Error(`Error parsing XML string:\n${errors.join("\n")}`);
  }

  return xmlDoc;
}

// Ensure KML string is well-formed and has necessary namespaces
function fixKML(inputData: string) {
  let data = inputData.replace(/^\uFEFF/, "").trimStart();

  if (!data.startsWith("<?xml")) {
    data = `<?xml version="1.0" encoding="UTF-8"?>\n` + data;
  } else if (!data.startsWith('<?xml version="1.0" encoding="UTF-8"?>')) {
    data = data.replace(
      /<\?xml\s+.*?\?>/,
      '<?xml version="1.0" encoding="UTF-8"?>'
    );
  }

  if (!data.includes("xmlns")) {
    data = data.replace(
      /<(\w+)([^>]*)>/,
      `<$1 xmlns="http://www.opengis.net/kml/2.2"$2>`
    );
  }

  if (!data.includes('xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"')) {
    data = data.replace(
      /<(\w+)([^>]*)>/,
      `<$1 xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"$2>`
    );
  }

  if (
    !data.includes(
      'xsi:schemaLocation="http://www.opengis.net/kml/2.2 http://schemas.opengis.net/kml/2.2.0/ogckml22.xsd"'
    )
  ) {
    data = data.replace(
      /<(\w+)([^>]*)>/,
      `<$1 xsi:schemaLocation="http://www.opengis.net/kml/2.2 http://schemas.opengis.net/kml/2.2.0/ogckml22.xsd"$2>`
    );
  }

  return data;
}

// Check if a file is zipped based on its signature - https://en.wikipedia.org/wiki/List_of_file_signatures
export function isZipped(file: File | Blob): Promise<boolean> {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onloadend = (evt) => {
      if (evt.target?.readyState === FileReader.DONE) {
        const uint = new Uint8Array(evt.target.result as ArrayBuffer);
        resolve(String.fromCharCode(...uint.slice(0, 2)) === "PK");
      }
    };
    const blob = file.slice(0, 2);
    fileReader.readAsArrayBuffer(blob);
  });
}

// Normalize GeoJSON by converting GeometryCollection to Features
function normalizeGeojson(geojson: GeoFeatures): GeoFeatures {
  if (geojson.type === "FeatureCollection") {
    return Object.assign({}, geojson, {
      features: geojson.features.reduce(
        (result, each) => result.concat(geometryToFeature(each)),
        [] as GeoJSON.Feature<GeoJSON.Geometry>[]
      ),
    });
  }
  return geojson;
}

/// Convert geometries within a Feature to individual Features
function geometryToFeature(
  geojson: GeoJSON.Feature<GeoJSON.GeometryCollection | GeoJSON.Geometry>
): GeoJSON.Feature<GeoJSON.Geometry>[] {
  if (
    geojson.type === "Feature" &&
    geojson.geometry.type === "GeometryCollection"
  ) {
    return (geojson as any).geometry.geometries.map(
      (geometry: GeoJSON.Geometry) =>
        clearProperties(Object.assign({}, geojson, { geometry }))
    );
  }

  return [clearProperties(geojson as GeoJSON.Feature<GeoJSON.Geometry>)];
}

function clearProperties(feature: GeoJSON.Feature<GeoJSON.Geometry>) {
  feature.properties = {};
  return feature;
}
