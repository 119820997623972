import MapGl, {
  ControlPosition,
  LngLatBoundsLike,
  MapProps,
  MarkerProps,
  useControl,
} from "react-map-gl";
import MapboxGeocoder, {
  GeocoderOptions,
  Result,
} from "@mapbox/mapbox-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import findCenter from "@turf/center";
import calculateArea from "@turf/area";
import "mapboxgl-legend/dist/style.css";
import { defineMessages, useIntl } from "react-intl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useEffectOnce } from "react-use";
import { MapContextValue } from "react-map-gl/dist/esm/components/map";
import bbox from "@turf/bbox";

export default function Map(props: MapProps) {
  return (
    <MapGl
      mapboxAccessToken={process.env.MAP_TOKEN || ""}
      mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
      {...props}
    />
  );
}

type GeocoderControlProps = Omit<
  GeocoderOptions,
  "accessToken" | "mapboxgl" | "marker"
> & {
  marker?: boolean | Omit<MarkerProps, "longitude" | "latitude">;

  position: ControlPosition;

  onLoading?: (e: object) => void;
  onResults?: (e: object) => void;
  onResult?: (e: object) => void;
  onError?: (e: object) => void;
};

const messages = defineMessages({
  geocoderPlaceholder: {
    id: "maps.geocoderPlaceholder",
    defaultMessage: "Search by location or coordinates",
  },
});

export function MapboxGeocoderControl(props: GeocoderControlProps) {
  const intl = useIntl();

  useControl<MapboxGeocoder>(
    () => {
      return new MapboxGeocoder({
        ...props,
        marker: false,
        accessToken: process.env.MAP_TOKEN || "",
        reverseGeocode: true,
        localGeocoder: coordinatesGeocoder,
        placeholder: intl.formatMessage(messages.geocoderPlaceholder),
      });
    },
    {
      position: props.position,
    }
  );

  return null;
}

/*
 * Code from https://docs.mapbox.com/mapbox-gl-js/example/mapbox-gl-geocoder-accept-coordinates/
 *
 * Given a query in the form "lng, lat" or "lat, lng"
 * returns the matching geographic coordinate(s)
 * as search results in carmen geojson format,
 * https://github.com/mapbox/carmen/blob/master/carmen-geojson.md */
const coordinatesGeocoder = function (query: string) {
  // Match anything which looks like
  // decimal degrees coordinate pair.
  const matches = query.match(
    /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
  );

  if (!matches) {
    return [] as Result[];
  }

  function coordinateFeature(lng: number, lat: number) {
    return {
      center: [lng, lat],
      geometry: {
        type: "Point",
        coordinates: [lng, lat],
      },
      place_name: "Lat: " + lat + " Lng: " + lng,
      place_type: ["coordinate"],
      properties: {},
      type: "Feature",
    } as Result;
  }

  const coord1 = Number(matches[1]);
  const coord2 = Number(matches[2]);
  const geocodes = [];

  if (coord1 < -90 || coord1 > 90) {
    // must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2));
  }

  if (coord2 < -90 || coord2 > 90) {
    // must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  if (geocodes.length === 0) {
    // else could be either lng, lat or lat, lng
    geocodes.push(coordinateFeature(coord1, coord2));
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  return geocodes;
};

export type GeoFeatures = GeoJSON.FeatureCollection<
  | GeoJSON.Point
  | GeoJSON.MultiPoint
  | GeoJSON.LineString
  | GeoJSON.MultiLineString
  | GeoJSON.Polygon
  | GeoJSON.MultiPolygon
>;

export type MapCoordinate = [number, number]; // longitude, latitude

export function findGeoCenter(geoJson?: GeoFeatures | null) {
  if (!geoJson || geoJson.features.length === 0) return;

  return findCenter(geoJson);
}

export function calculateGeoJsonArea(geoJson?: GeoFeatures) {
  if (!geoJson) return 0;

  return geoJson.features.reduce((sum: number, f: any) => {
    return sum + Math.round(calculateArea(f.geometry) * 100) / 100;
  }, 0);
}

export type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;

  onCreate?: (evt: { features: object[] }) => void;
  onUpdate?: (evt: { features: object[]; action: string }) => void;
  onDelete?: (evt: { features: object[] }) => void;
};

export function DrawControl(
  props: DrawControlProps & { setControl(control: MapboxDraw): void }
) {
  const control = useControl<MapboxDraw>(
    () => new MapboxDraw(props),
    ({ map }: MapContextValue) => {
      if (props.onCreate) map.on("draw.create", props.onCreate);
      if (props.onUpdate) map.on("draw.update", props.onUpdate);
      if (props.onDelete) map.on("draw.delete", props.onDelete);
    },
    ({ map }: MapContextValue) => {
      if (props.onCreate) map.off("draw.create", props.onCreate);
      if (props.onUpdate) map.off("draw.update", props.onUpdate);
      if (props.onDelete) map.off("draw.delete", props.onDelete);
    },
    {
      position: props.position,
    }
  );

  useEffectOnce(() => {
    props.setControl(control);
  });

  return null;
}

export function findBoundaries(value?: GeoFeatures | null) {
  if (!value || value.features.length === 0) return;

  return bbox(value) as LngLatBoundsLike;
}

export function findCenterFromValue(value?: GeoFeatures | null) {
  const center = findGeoCenter(value);

  if (!center) return;

  return center.geometry.coordinates as MapCoordinate;
}
