import {
  DeleteOutlined,
  DownloadOutlined,
  MoreOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useCurrentLocale, WithConditionalIDType } from "../../../lib/hooks";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useState, useEffect, useCallback, useMemo, ReactNode } from "react";
import { get, set } from "lodash";
import { TableSelect, TableSelectProps } from "./TableSelect";
import { Rule } from "antd/lib/form";
import {
  DeepFieldData,
  Form,
  NamePath,
  Upload,
  buildNamePath,
  useFormContext,
} from "../../form";
import { ColumnType } from "antd/lib/table/interface";
import { ItemFieldFormConfig } from "../ListView/ItemForm";
import { Sidebar } from "../Sidebar";
import { Dropdown } from "../Dropdown";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import Table, {
  TableProps,
  ColumnsType,
  TableContextProvider,
  useTableContext,
} from "../Table";
import { Button, Tooltip } from "antd";
import { Space } from "../Space";
import { readSpreadsheet } from "../../../lib/spreadsheets";
import confirm from "antd/lib/modal/confirm";

import {
  DndContext,
  DndContextProps,
  PointerSensor,
  useSensors,
  useSensor,
} from "@dnd-kit/core";
import {
  useSortable,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { BulkUpdateSidebar } from "../Table/BulkUpdateSidebar";

export type TableInputImportProps = {
  headers: Record<
    string,
    { label: string; editable?: boolean; transformer?(value?: number): number }
  >;
  tooltip?: ReactNode;
  filter?(variant: any): boolean;
};

export interface TableInputProps<T> {
  name: NamePath;
  dataSource: T[];
  columns: ColumnsType<T>;
  addIndexColumn?: boolean | ColumnType<T>;
  tableSelectProps?: TableSelectProps<T>;
  disabled?: boolean;
  markAsDestroyed?(entity: T): boolean;
  tableProps?: TableProps<T>;
  rowKey?(record: T): string;
  menuItems?(entity: T, index: number): ItemType[];
  hideMenu?: boolean;
  hideRemove?(entity: T, index: number): boolean;
  onRemove?(entity: T): void;
  rules?: Rule[];
  allowBulkRemove?: boolean;
  bulkUpdateFields?: ItemFieldFormConfig<T>[];
  onBulkUpdate?(values: T, updated: DeepFieldData[]): void;
  entityName?: string;
  rowSelectors?(entities: T[]): TableProps<T>["rowSelectors"];
  addSorter?(a: T, b: T): number;
  importProps?: TableInputImportProps & {
    templateUrl: string;
    onUpload?(data: any[]): void;
  };
  draggable?: DndContextProps;
  hideEmpty?: boolean;
}

const messages = defineMessages({
  bulkUpdate: {
    id: "bulkActions.update",
    defaultMessage: "bulkActions.update",
  },
  confirmDiscard: {
    id: "discard.confirm.generic",
    defaultMessage: "Are you sure want to remove these entities?",
  },
  missingColumns: {
    id: "imports.missingColumns",
    defaultMessage: "Imported file doesn't contain following columns: {value}",
  },
});

function markedForDestruction<T>(e: T) {
  return get(e, "_destroy");
}

function indexColumn<T>(
  column: boolean | ColumnType<T>,
  dataSource: () => T[]
): ColumnType<T> {
  return {
    width: 33,
    onCell: () => ({ style: { padding: 12, textAlign: "center" } }),
    key: "index",
    render: (_, r) => {
      return (
        dataSource()
          .filter((e) => !markedForDestruction(e))
          .indexOf(r) + 1
      );
    },
    ...(column === true ? {} : column),
  };
}

export function TableInput<T extends WithConditionalIDType>({
  columns,
  dataSource,
  disabled,
  tableProps,
  rowKey = (e) => e.id || "",
  draggable,
  ...props
}: TableInputProps<T>) {
  if (disabled) {
    return (
      <Table
        dataSource={dataSource}
        columns={
          props.addIndexColumn
            ? [indexColumn(props.addIndexColumn, () => dataSource), ...columns]
            : columns
        }
        rowKey={rowKey}
        pagination={false}
        {...tableProps}
      />
    );
  }

  const table = (
    <TableContextProvider>
      <EditableTableInput
        dataSource={dataSource}
        columns={columns}
        rowKey={rowKey}
        tableProps={{
          ...tableProps,
          components: draggable
            ? {
                body: { row: DraggableRow },
              }
            : undefined,
        }}
        {...props}
      />
    </TableContextProvider>
  );

  if (!draggable) return table;
  else
    return (
      <Draggable dndContext={draggable} rowKeys={dataSource.map(rowKey)}>
        {table}
      </Draggable>
    );
}

function Draggable({
  dndContext,
  rowKeys,
  children,
}: React.HTMLAttributes<HTMLTableElement> & {
  rowKeys: string[];
  dndContext: DndContextProps;
}) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );
  return (
    <DndContext
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      sensors={sensors}
      {...dndContext}
    >
      <SortableContext items={rowKeys} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
}

function DraggableRow(
  props: React.HTMLAttributes<HTMLTableRowElement> & { "data-row-key": string }
) {
  const id = props["data-row-key"];
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isSorting,
  } = useSortable({ id });

  return (
    <tr
      id={id}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      {...props}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: "move",
        zIndex: isSorting ? 1000 : undefined,
        opacity: isSorting ? 0.9 : undefined,
      }}
    />
  );
}

function EditableTableInput<T extends WithConditionalIDType>({
  name,
  tableSelectProps,
  columns,
  markAsDestroyed = (e) => !!e.id,
  tableProps = {},
  menuItems,
  hideMenu,
  hideRemove = () => false,
  onRemove,
  rowKey = (e) => e.id || "",
  rules,
  allowBulkRemove,
  bulkUpdateFields = [],
  onBulkUpdate,
  entityName,
  rowSelectors,
  addSorter,
  addIndexColumn,
  importProps,
  hideEmpty,
}: TableInputProps<T>) {
  const { showError } = useCurrentLocale();

  const allowBulkUpdate = !!bulkUpdateFields.length;

  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => setShouldUpdate(false), [shouldUpdate]);

  const intl = useIntl();

  const { selectedRowKeys, setSelectedRowKeys } = useTableContext();
  const clearSelection = () => setSelectedRowKeys([]);

  const [visibleMassUpdateForm, setMassUpdateFormVisibility] = useState(false);
  const toggleMassUpdateForm = useCallback(
    () => setMassUpdateFormVisibility(!visibleMassUpdateForm),
    [visibleMassUpdateForm]
  );

  const { form } = useFormContext();

  const removeEntity = useCallback(
    (key: React.Key) => {
      const source = form.getFieldValue(name) as T[];
      const entity = source.find((e) => rowKey(e) === key);

      if (!entity) return;

      if (markAsDestroyed && markAsDestroyed(entity)) {
        // for persisted records
        const index = source.findIndex((e) => rowKey(e) === key);
        form.setFields([
          {
            name: buildNamePath(name, index, "_destroy"),
            value: true,
          },
        ]);
      } else {
        // for new records
        form.setFields([
          {
            name,
            value: source.filter((e) => rowKey(e) !== key),
          },
        ]);
      }

      if (onRemove) onRemove(entity);
    },
    [form, markAsDestroyed, name, onRemove, rowKey]
  );

  const rowClassName = useCallback(
    (r: any, index: any, indent: any) =>
      markedForDestruction(r)
        ? "hide"
        : tableProps?.rowClassName instanceof Function
        ? tableProps.rowClassName(r, index, indent)
        : "",
    [tableProps]
  );

  const tableColumns = useMemo(() => {
    const result = [...columns];

    if (!hideMenu) {
      result.push({
        width: 50,
        render: (_, entity, index) => {
          const items = menuItems ? menuItems(entity, index) : [];
          const removeHidden = hideRemove(entity, index);
          if (items.length === 0 && removeHidden) return;

          if (!removeHidden) {
            items.push({
              key: "remove-item",
              onClick: () => {
                const key = rowKey(entity);
                removeEntity(key);
                setSelectedRowKeys((currentRowKeys) =>
                  currentRowKeys.filter((e) => e !== key)
                );
                setShouldUpdate(true);
              },
              icon: <DeleteOutlined />,
              danger: true,
              label: <FormattedMessage id="remove" defaultMessage="remove" />,
            });
          }

          return (
            <Dropdown menu={{ triggerSubMenuAction: "click", items }}>
              <MoreOutlined />
            </Dropdown>
          );
        },
      });
    }

    return result;
  }, [
    columns,
    hideMenu,
    hideRemove,
    menuItems,
    removeEntity,
    rowKey,
    setSelectedRowKeys,
  ]);

  const onTemplateUpload = useCallback(
    async (file?: File) => {
      if (!file || !importProps?.onUpload) return;

      const source = form.getFieldValue(name) as T[];
      source.forEach((s) => removeEntity(rowKey(s)));
      setShouldUpdate(true);

      const data = await readSpreadsheet(file);

      const testValue = data[0] as Record<string, any>;

      const missingColumns = Object.values(importProps.headers).filter(
        ({ editable, label }) => editable && !(label in testValue)
      );

      if (missingColumns.length > 0) {
        showError(
          intl.formatMessage(messages.missingColumns, {
            value: missingColumns.map((c) => c.label).join(", "),
          })
        );
        return;
      }

      importProps.onUpload(data);
      setShouldUpdate(true);
    },
    [form, importProps, intl, name, removeEntity, rowKey]
  );

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prev, next) =>
        shouldUpdate ||
        get(prev, buildNamePath(name, "length")) !==
          get(next, buildNamePath(name, "length"))
      }
    >
      {({ getFieldValue, setFields }) => {
        const source = getFieldValue(name) as T[];

        if (
          addIndexColumn &&
          (tableColumns.length == 0 || tableColumns[0].key != "index")
        ) {
          tableColumns.splice(
            0,
            0,
            indexColumn(addIndexColumn, () => getFieldValue(name))
          );
        }

        if (rowSelectors) {
          tableProps.rowSelectors = rowSelectors(
            source.filter((e) => !markedForDestruction(e))
          );
        }

        if (allowBulkRemove || allowBulkUpdate) {
          tableProps.selectableFilter = (source) =>
            !!source && source.some((e) => !markedForDestruction(e));
        }

        if (allowBulkRemove) {
          tableProps.onBulkRemove = (currentRowKeys: React.Key[]) => {
            confirm({
              title: intl.formatMessage(messages.confirmDiscard),
              async onOk() {
                currentRowKeys.forEach(removeEntity);
                setShouldUpdate(true);
                setSelectedRowKeys([]);
              },
              onCancel() {},
            });
          };
        }

        if (allowBulkUpdate) {
          tableProps.onBulkUpdate = (currentRowKeys: React.Key[]) => {
            setSelectedRowKeys(currentRowKeys);
            toggleMassUpdateForm();
          };
        }

        const hideTable =
          hideEmpty && !source?.filter((e) => !markedForDestruction(e)).length;

        return (
          <Space vertical style={{ width: "100%" }}>
            {allowBulkUpdate && (
              <Sidebar
                open={visibleMassUpdateForm}
                setVisible={toggleMassUpdateForm}
              >
                <BulkUpdateSidebar
                  entityName={entityName}
                  onCancel={toggleMassUpdateForm}
                  onSave={(values) => {
                    return Promise.resolve(null).then(() => {
                      const updatedEntities = selectedRowKeys.flatMap((key) => {
                        const index = source.findIndex((s) => rowKey(s) == key);

                        return Object.entries(values).map(([field, value]) => ({
                          name: buildNamePath(name, index, field),
                          value,
                        }));
                      });

                      setFields(updatedEntities);
                      toggleMassUpdateForm();
                      clearSelection();
                      if (onBulkUpdate) onBulkUpdate(values, updatedEntities);
                      setShouldUpdate(true);

                      return { result: true, errors: [] };
                    });
                  }}
                  fields={bulkUpdateFields}
                />
              </Sidebar>
            )}

            {/* Space sucks here because it has childs that aren't 100% width */}
            <div style={{ display: "flex", gap: "16px" }}>
              {tableSelectProps && (
                <Selector
                  name={name}
                  rowKey={rowKey}
                  tableSelectProps={tableSelectProps}
                  addSorter={addSorter}
                  setShouldUpdate={setShouldUpdate}
                />
              )}

              {importProps && (
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: (
                          <FormattedMessage
                            id="imports.hint"
                            defaultMessage="Download template file, fill it and upload back"
                          />
                        ),
                        key: "hint",
                        type: "group",
                      },
                      {
                        key: "download",
                        icon: <DownloadOutlined />,
                        label: (
                          <a
                            download
                            href={importProps.templateUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FormattedMessage
                              id="imports.downloadTemplate"
                              defaultMessage="Download template"
                            />
                          </a>
                        ),
                      },
                      {
                        key: "upload",
                        icon: <UploadOutlined />,
                        label: (
                          <Upload
                            accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            listType="text"
                            onChange={onTemplateUpload}
                            className="upload-in-dropdown"
                          >
                            <FormattedMessage
                              id="imports.uploadTemplate"
                              defaultMessage="Upload template"
                            />
                          </Upload>
                        ),
                      },
                    ],
                  }}
                >
                  <Tooltip title={importProps.tooltip} placement="bottom">
                    <Button icon={<UploadOutlined />}>
                      <span>
                        <FormattedMessage id="import" defaultMessage="Import" />
                      </span>
                    </Button>
                  </Tooltip>
                </Dropdown>
              )}
            </div>

            {!hideTable && (
              <Table
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                dataSource={source}
                rowKey={rowKey}
                pagination={false}
                // columns can be memoized if we memoize hideRemove and menuItems
                columns={tableColumns}
                {...tableProps}
                rowClassName={rowClassName}
              />
            )}

            {rules && (
              <Form.Item name={name} rules={rules} compact>
                <div />
              </Form.Item>
            )}
          </Space>
        );
      }}
    </Form.Item>
  );
}

// memoizing TableSelect to avoid re-rendering within parent
const Selector = <T extends WithConditionalIDType>({
  name,
  rowKey = (e) => e.id || "",
  addSorter,
  tableSelectProps,
  setShouldUpdate,
}: Pick<
  TableInputProps<T>,
  "name" | "rowKey" | "addSorter" | "tableSelectProps"
> & { setShouldUpdate: (update: boolean) => void }) => {
  const { form } = useFormContext();

  return (
    <TableSelect
      onAdd={(selectedEntities) => {
        const newSource: T[] = [];
        const source = form.getFieldValue(name) as T[];

        selectedEntities.forEach((item) => {
          (Array.isArray(item) ? item : [item]).forEach((i) => {
            const destroyedEntity = source.find(
              (e) => markedForDestruction(e) && rowKey(e) == rowKey(i)
            );

            if (destroyedEntity) {
              //restore previously deleted item
              set(destroyedEntity, "_destroy", undefined);
              // const { id, ...props } = i;
              // assign(destroyedEntity, props, { _destroy: undefined });
            } else if (
              !source.find((e) => rowKey(e) == rowKey(i)) &&
              !newSource.find((e) => rowKey(e) == rowKey(i))
            ) {
              newSource.push(i);
            }
          });
        });

        form.setFields([
          {
            name,
            value: [
              ...source,
              ...(addSorter ? newSource.sort(addSorter) : newSource),
            ],
          },
        ]);

        form.validateFields([name]);

        setShouldUpdate(true);

        tableSelectProps?.onSearch && tableSelectProps.onSearch("");
      }}
      {...tableSelectProps}
    />
  );
};
