import {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Map, {
  findBoundaries,
  findCenterFromValue,
  GeoFeatures,
  MapboxGeocoderControl,
  MapCoordinate,
} from "../../../lib/mapbox/map";
import LegendControl from "mapboxgl-legend";
import {
  FullscreenControl,
  Layer,
  NavigationControl,
  Popup,
  PopupProps,
  Source,
  SourceProps,
  GeolocateControl,
  MapLayerMouseEvent,
  MapLayerTouchEvent,
  MapEvent,
  MapboxGeoJSONFeature,
  LineLayer,
  LayerProps,
  MapProps,
} from "react-map-gl";
import { LayerProps as _LayerProps } from "react-map-gl/dist/esm/components/layer";
import { MapValue } from "../MapInput/MapInput";

export type LayersProps = Array<
  LayerProps & {
    id: string;
    popup?: (feature: MapboxGeoJSONFeature) => ReactNode | null;
    legend?: boolean;
  }
>;

export interface MapViewProps extends MapProps {
  height?: string | number;
  zoom?: number;
  center?: MapCoordinate;
  defaultValue?: MapValue["value"];
  geoJsonBoundaries?: { data: GeoFeatures };
  geoJsonLayers?: Array<_LayerProps<LineLayer> & { data: GeoFeatures }>;
  sources?: Array<SourceProps>;
  layers?: LayersProps;
  currentLayer?: string;
  children?: ReactElement;
  onLoad?(e: MapEvent): void;
  onClick?(): void;
}

const DEFAULT_CENTER: MapCoordinate = [-85.3291625675152, 12.988101618735556];

function toggleMapCursor(map: mapboxgl.Map, cursor: "" | "pointer") {
  map.getCanvas().style.cursor = cursor;
}

export default function MapView({
  center,
  zoom,
  height = "100%",
  defaultValue,
  geoJsonBoundaries,
  geoJsonLayers,
  sources,
  layers,
  currentLayer,
  children,
  onClick,
  onLoad,
  ...props
}: MapViewProps) {
  const [legend, setLegend] = useState<LegendControl | null>(null);
  const [popup, setPopup] = useState<PropsWithChildren<PopupProps>>();

  const legendLayers = useMemo(
    () => layers?.filter((l) => l.legend).map((l) => l.id),
    [layers]
  );

  useEffect(() => {
    if (!legendLayers || !legend || !currentLayer) return;

    setPopup(undefined);

    const layersToRemove = Array.from<string>(
      // @ts-expect-error accessing mapbox legend's private API
      legend._options.layers.keys()
    ).filter((l) => l !== currentLayer);

    legend?.removeLayers(layersToRemove);

    const layerToAdd = legendLayers.find((l) => l === currentLayer);

    if (layerToAdd) legend?.addLayers([layerToAdd]);
  }, [currentLayer, legend, legendLayers]);

  const onMapLoad = useCallback(
    (e: MapEvent) => {
      if (legendLayers) {
        const legend = new LegendControl({
          layers: legendLayers,
        });

        // @ts-expect-error accessing mapbox legend's private API
        e.target.addControl(legend, "top-left");
        setLegend(legend);
      }

      if (onLoad) onLoad(e);
    },
    [legendLayers, onLoad]
  );

  const onMouseMove = useCallback(
    (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
      const hoveredFeature = e.features?.[0];

      if (hoveredFeature) {
        toggleMapCursor(e.target, "pointer");

        const layer = layers?.find((l) => l.id === hoveredFeature.layer.id);

        const popupOnMouse = layer?.popup;

        if (popupOnMouse) {
          const popupContent = popupOnMouse(hoveredFeature);

          if (!popupContent) return;

          setPopup({
            longitude: e.lngLat.lng,
            latitude: e.lngLat.lat,
            children: popupContent,
          });
        }
      }
    },
    [layers]
  );

  const onMouseLeave = useCallback(
    (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
      setPopup(undefined);
      toggleMapCursor(e.target, "");
    },
    []
  );

  const onMapClick = useCallback(() => {
    setPopup(undefined);
    if (onClick) onClick();
  }, [onClick]);

  const value = geoJsonBoundaries?.data || defaultValue;

  const mapCenter = findCenterFromValue(value) || DEFAULT_CENTER;

  return (
    <Map
      reuseMaps={!legendLayers?.length}
      initialViewState={{
        bounds: findBoundaries(value),
        zoom: zoom || 14,
        latitude: mapCenter[1],
        longitude: mapCenter[0],
        fitBoundsOptions: {
          padding: 20,
        },
      }}
      mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
      style={{
        height,
        width: "100%",
      }}
      interactiveLayerIds={legendLayers}
      onLoad={onMapLoad}
      onClick={onMapClick}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onTouchStart={onMouseLeave}
      {...props}
    >
      {sources && sources.map((s) => <Source key={`source-${s.id}`} {...s} />)}

      {layers &&
        layers.map(({ popup, ...l }) => <Layer key={`layer-${l.id}`} {...l} />)}

      {geoJsonLayers &&
        geoJsonLayers.map((geoJsonLayer, i) => (
          <Source
            key={`geo-source-${i}`}
            type="geojson"
            data={geoJsonLayer.data}
          >
            <Layer {...geoJsonLayer} />
          </Source>
        ))}

      {popup && <Popup maxWidth="200" {...popup} />}

      <NavigationControl position="bottom-right" />
      <FullscreenControl position="bottom-right" />
      <MapboxGeocoderControl position="top-right" />
      <GeolocateControl position="top-right" />

      {children}
    </Map>
  );
}
