import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
  ApolloClient,
} from "@apollo/client";

import {
  ImportsDocument,
  ImportsQuery,
  ImportsQueryVariables,
  ImportCreateMutation,
  ImportCreateMutationVariables,
  ImportCreateDocument,
  ImportQuery,
  ImportQueryVariables,
  ImportDocument,
  ImportStatusTypeQuery,
  ImportStatusTypeDocument,
  ImportKindType,
  ImportUpdateMutation,
  ImportUpdateMutationVariables,
  ImportUpdateDocument,
  ImportFireEventMutation,
  ImportFireEventMutationVariables,
  ImportFireEventDocument,
  ImportModulesType,
} from "../../graphql";
import { useIntl } from "react-intl";
import { useCallback } from "react";
import { formatImportStatus } from "../../formats/imports";

export function useImportStatusOptions() {
  const [load, { data, loading }] = useLazyQuery<ImportStatusTypeQuery>(
    ImportStatusTypeDocument
  );

  return {
    load,
    loading,
    options: data?.__type?.enumValues?.map((t) => ({
      key: t.name,
      label: formatImportStatus(t.name),
    })),
  };
}

const IMPORT_MODULE_GROUPS = {
  hr: [ImportModulesType.Employee, ImportModulesType.Position],
  inventory: [ImportModulesType.Variant, ImportModulesType.ItemCategory],
  finance: [ImportModulesType.Account, ImportModulesType.Counterparty],
  fleet: [ImportModulesType.Machine],
  agriculture: [
    ImportModulesType.Activity,
    ImportModulesType.CropField,
    ImportModulesType.WeatherMeasurement,
  ],
};

export function useImportModules() {
  const intl = useIntl();

  const moduleOptions = Object.entries(IMPORT_MODULE_GROUPS).flatMap(
    ([group, v]) => {
      return v.map((key) => ({
        key,
        group: intl.formatMessage({ id: group }),
        label: intl.formatMessage({ id: `imports.modules.${key}.plural` }),
      }));
    }
  );

  const formatModuleName = useCallback(
    (moduleName: ImportModulesType) => {
      const moduleGroup = Object.entries(moduleOptions).find(([, v]) => {
        return v.key === moduleName;
      })?.[1];

      return `${moduleGroup?.label} / ${moduleGroup?.group}`;
    },
    [moduleOptions]
  );

  return { moduleOptions, formatModuleName };
}

export function useImportKinds() {
  const intl = useIntl();

  const kindOptions = Object.entries(ImportKindType).map(([, key]) => ({
    key,
    label: intl.formatMessage({ id: `imports.kinds.${key}` }),
  }));

  return { kindOptions };
}

export function useImports(
  options?: QueryHookOptions<ImportsQuery, ImportsQueryVariables>
) {
  const { loading, data, refetch } = useQuery(ImportsDocument, options);

  if (!data || !data.imports || !data.imports.items) {
    return { loading };
  }

  const items = data.imports.items;
  const totalCount = data.imports.totalCount;

  return { loading, items, totalCount, refetch };
}

export function useImport({
  id,
  onCompleted,
}: {
  id: string;
  onCompleted?: (data: ImportQuery) => void;
}) {
  const pollInterval = 5000;

  const { data, startPolling, stopPolling } = useQuery<
    ImportQuery,
    ImportQueryVariables
  >(ImportDocument, {
    variables: { id },
    pollInterval,
    notifyOnNetworkStatusChange: true, // it is necessary to trigger onCompleted on polling
    onCompleted,
  });

  return { import: data?.import, pollInterval, startPolling, stopPolling };
}

export function watchImport(
  client: ApolloClient<object>,
  id: string,
  onCompleted: (data?: ImportQuery | null) => boolean
) {
  const pollInterval = 5000;

  const query = client.watchQuery<ImportQuery, ImportQueryVariables>({
    query: ImportDocument,
    variables: { id },
    pollInterval,
  });

  return query.subscribe((observer) => {
    if (onCompleted(observer.data)) {
      query.stopPolling();
    }
  });
}

export function useImportCreate(
  options?: MutationHookOptions<
    ImportCreateMutation,
    ImportCreateMutationVariables
  >
) {
  return useMutation(ImportCreateDocument, options);
}

export function useImportUpdate(
  options?: MutationHookOptions<
    ImportUpdateMutation,
    ImportUpdateMutationVariables
  >
) {
  return useMutation(ImportUpdateDocument, options);
}

export function useImportFireEventMutation(
  options?: MutationHookOptions<
    ImportFireEventMutation,
    ImportFireEventMutationVariables
  >
) {
  return useMutation(ImportFireEventDocument, options);
}
