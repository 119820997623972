import { Button, Progress, Space } from "antd";
import Link from "next/link";
import { FormattedMessage } from "react-intl";
import { colors } from "../../../lib/formats";
import { useOnboardingProgress } from "../../../lib/hooks/onboarding";
import routes from "../../../lib/routes";

export default function OnboardingProgress() {
  const onboardingProgress = useOnboardingProgress();

  if (!onboardingProgress) return null;

  return (
    <Space align="center">
      <Progress
        type="circle"
        percent={onboardingProgress}
        width={40}
        strokeColor={{
          "0%": colors.aragroColor,
          "100%": colors.successColor,
        }}
      />

      <Link href={routes.onboarding} legacyBehavior>
        <Button href={routes.onboarding} data-action="continue-onboarding">
          <FormattedMessage
            id="onboarding.pageTitle"
            defaultMessage="Onboarding checklist"
          />
        </Button>
      </Link>
    </Space>
  );
}
