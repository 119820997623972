"use client";

import { Spin } from "antd";
import { cloneDeep } from "lodash";
import { colors } from "../../../lib/formats";
import { CropFieldFragment, LocalityFragment } from "../../../lib/graphql";
import { useCropFields } from "../../../lib/hooks";
import MapInput, { MapInputProps } from "../../shared/MapInput/MapInput";
import MapView, { MapViewProps } from "../../shared/MapView";
import { useMemo } from "react";

export default function LocalityMap({
  locality,
  mapProps,
  mapInputProps,
  cropField,
  hideCropFields,
  readonly,
}: {
  locality?: Pick<LocalityFragment, "id" | "name" | "geoJson">;
  mapProps?: MapViewProps;
  mapInputProps?: MapInputProps;
  cropField?: CropFieldFragment;
  hideCropFields?: boolean;
  readonly?: boolean;
}) {
  const { items, loading } = useCropFields({
    skip: !locality || hideCropFields || !!cropField,
    variables: {
      filter: { localityId: locality ? [locality.id] : undefined },
      pageSize: 1000,
    },
  });

  const geoJsonLayers: MapViewProps["geoJsonLayers"] = useMemo(() => {
    const cropFields: CropFieldFragment[] = [];

    const data = [] as Array<{
      geo: GeoJSON.FeatureCollection<GeoJSON.Polygon>;
      color: string;
    }>;

    if (!hideCropFields) {
      if (cropField) {
        cropFields.push(cropField);
      } else if (items) {
        cropFields.push(...items);
      }

      const cropFieldFeatures = buildCropFieldFeatureCollection(cropFields);

      data.push({ color: colors.aragroColor, geo: cropFieldFeatures });
    }

    if (locality?.geoJson) {
      data.push({ color: colors.successColor, geo: locality.geoJson });
    }

    return data.length > 0
      ? data.map(({ color, geo }) => ({
          data: geo,
          type: "line" as const,
          paint: {
            "line-color": color || colors.successColor,
            "line-width": 2,
          },
        }))
      : undefined;
  }, [cropField, hideCropFields, items, locality?.geoJson]);

  if (loading) return <Spin />;

  if (readonly) {
    return (
      <MapView
        {...mapProps}
        geoJsonBoundaries={
          mapProps?.geoJsonBoundaries
            ? mapProps.geoJsonBoundaries
            : cropField
            ? { data: cropField.geoJson }
            : locality?.geoJson
            ? { data: locality.geoJson }
            : geoJsonLayers && geoJsonLayers.length > 0
            ? geoJsonLayers[0]
            : undefined
        }
        geoJsonLayers={geoJsonLayers}
      />
    );
  }

  return (
    <MapInput
      {...mapProps}
      {...mapInputProps}
      geoJsonBoundaries={
        mapProps?.geoJsonBoundaries
          ? mapProps.geoJsonBoundaries
          : mapProps?.defaultValue
          ? { data: mapProps?.defaultValue }
          : locality?.geoJson
          ? { data: locality.geoJson }
          : geoJsonLayers && geoJsonLayers.length > 0
          ? geoJsonLayers[0]
          : undefined
      }
      geoJsonLayers={geoJsonLayers}
    />
  );
}

export function buildCropFieldFeatureCollection(items?: CropFieldFragment[]) {
  return {
    type: "FeatureCollection" as GeoJSON.FeatureCollection["type"],
    features: !items
      ? []
      : items
          .filter((g) => !!g.geoJson)
          .flatMap((c) => {
            const feature = cloneDeep(
              c.geoJson as GeoJSON.FeatureCollection<GeoJSON.Polygon>
            );

            return feature.features.map((f) => ({
              ...f,
              properties: {
                id: c.id,
                name: c.name,
              } as Record<string, any>,
            }));
          }),
  };
}
