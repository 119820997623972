import React from "react";

interface LogoProps {
  style: "white" | "dark";
  size: "tiny" | "small" | "medium" | "large";
}

const logoStyles = {
  white: "/images/logos/logo-white.svg",
  dark: "/images/logos/vertical@2x.svg",
};

const sizes = {
  tiny: "50px",
  small: "75px",
  medium: "150px",
  large: "225px",
};

export class Logo extends React.PureComponent<LogoProps> {
  static defaultProps = {
    style: "dark",
    size: "small",
  };

  render() {
    return (
      <>
        <img src={logoStyles[this.props.style]} alt="ARA Logo" />
        <style jsx>{`
          img {
            width: 100%;
            max-width: ${sizes[this.props.size]};
          }
        `}</style>
      </>
    );
  }
}
