import { ReactNode, useCallback, useState } from "react";
import {
  calculateGeoJsonArea,
  DrawControl,
  findBoundaries,
  findCenterFromValue,
  GeoFeatures,
} from "../../../lib/mapbox/map";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { FormattedMessage } from "react-intl";
import { Button, Space, Tooltip } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { kmzToGeoJson } from "../../../lib/mapbox/kmz";
import { Upload } from "../../form";
import { LayerProps } from "react-map-gl";
import MapView, { MapViewProps } from "../MapView";
import { captureException, showReportDialog } from "@sentry/nextjs";
import { useCurrentLocale } from "../../../lib/hooks";

export const symbolLayout: mapboxgl.SymbolLayout = {
  "text-field": "{name}",
  "text-offset": [0, 0],
  "text-size": 12,
};

export const symbolPaint: mapboxgl.SymbolPaint = {
  "text-color": "white",
};

export interface MapValue {
  /** GeoJSON object */
  geoJson: GeoFeatures;
  /** GeoJSON or coordinates */
  value?: GeoFeatures | null;
  /** Total area in square meters */
  area: number;
}

type MapMouseEvent = {
  target: mapboxgl.Map;
  lngLat: number[];
  features: Array<{ id: string; properties: any }>;
};

export type LayersProps = Array<
  LayerProps & {
    popup?: (feature: MapMouseEvent["features"][0]) => ReactNode | null;
    legend?: boolean;
  }
>;

export enum DrawMode {
  Select = "simple_select",
  DrawPoint = "draw_point",
  DrawPolygon = "draw_polygon",
}

export interface MapInputProps extends MapViewProps {
  readonly?: boolean;
  defaultValue?: MapValue["value"];
  defaultMode?: MapboxDraw.DrawMode;
  fillColor?: string;
  disablePolygon?: boolean;
  disableImport?: boolean;
  disablePoint?: boolean;
  onChange?(value: MapValue): void;
}

type Features = {
  [key: string]: GeoFeatures["features"][0];
};

export default function MapInput({
  defaultValue,
  defaultMode = "simple_select",
  readonly = false,
  disablePolygon,
  disableImport,
  disablePoint,
  onChange,
  ...props
}: MapInputProps) {
  const [viewState, setViewState] = useState<MapInputProps>();
  const [drawControl, setDrawControl] = useState<MapboxDraw>();
  // memoize features
  const [, setFeatures] = useState<Features>({});
  const { showError } = useCurrentLocale();

  const setBoundsAndCenter = useCallback((value?: GeoFeatures) => {
    const boundaries = findBoundaries(value);

    const mapCenter = findCenterFromValue(value);

    if (mapCenter && boundaries) {
      setViewState((state) => ({
        ...state,
        latitude: mapCenter?.[1],
        longitude: mapCenter?.[0],
        bounds: boundaries,
      }));
    }
  }, []);

  const onFeaturesChange = useCallback(
    (features: Features) => {
      if (!onChange || readonly) return;

      const geoJson: GeoFeatures = {
        type: "FeatureCollection",
        features: Object.values(features),
      };

      onChange({
        geoJson,
        area: calculateGeoJsonArea(geoJson),
        value: geoJson.features.length > 0 ? geoJson : null,
      });
    },
    [onChange, readonly]
  );

  const assignDrawControl = useCallback(
    (draw: MapboxDraw) => {
      if (!draw) return;

      if (readonly) {
        draw.changeMode("static");
      } else {
        draw.changeMode(defaultMode as any);
      }

      if (defaultValue) {
        const featureIds = draw.set(defaultValue);

        setFeatures((currFeatures) => {
          const newFeatures = { ...currFeatures };

          defaultValue.features.forEach((f, idx) => {
            newFeatures[featureIds[idx]] = f;
          });

          onFeaturesChange(newFeatures);

          return newFeatures;
        });
      }

      setDrawControl(draw);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [readonly, defaultValue, defaultMode]
  );

  const onDrawChange = useCallback(
    (e: any) => {
      if (readonly) return;

      setFeatures((currFeatures) => {
        const newFeatures = {
          ...currFeatures,
        };

        for (const f of e.features) {
          newFeatures[f.id] = f;
        }

        onFeaturesChange(newFeatures);

        return newFeatures;
      });
    },
    [readonly]
  );

  const onDrawDelete = useCallback(
    (e: any) => {
      if (readonly) return;

      setFeatures((currFeatures) => {
        const newFeatures = { ...currFeatures };

        for (const f of e.features) {
          delete newFeatures[f.id];
        }

        onFeaturesChange(newFeatures);

        return newFeatures;
      });
    },
    [onFeaturesChange, readonly]
  );

  const recenter = useCallback(() => {
    if (!drawControl) return;

    const geoJson = drawControl.getAll() as GeoFeatures;

    setBoundsAndCenter(geoJson);
  }, [drawControl, setBoundsAndCenter]);

  const setDrawControlValue = useCallback(() => {
    if (!drawControl || readonly) return;

    const geoJson = drawControl.getAll() as GeoFeatures;

    const features = geoJson.features.reduce((acc, f) => {
      if (!f.id) return acc;
      acc[f.id] = f;
      return acc;
    }, {} as Features);

    setFeatures(features);

    onFeaturesChange(features);
  }, [drawControl, onFeaturesChange, readonly]);

  const onKmlUpload = useCallback(
    async (file: File | Blob) => {
      if (!drawControl) return;

      try {
        const geoJson = await kmzToGeoJson(file);

        if (!geoJson) return;

        drawControl.add(geoJson);
        setDrawControlValue();
        recenter();
      } catch (e: any) {
        showError(e.message);

        const sentryEventId = captureException(e);
        showReportDialog({ eventId: sentryEventId });
      }
    },
    [drawControl, setDrawControlValue, recenter]
  );

  const clearMap = useCallback(() => {
    if (!drawControl) return;

    drawControl.deleteAll();
    setDrawControlValue();
  }, [drawControl, setDrawControlValue]);

  return (
    <>
      {!readonly && !disableImport && (
        <Space style={{ paddingBottom: "8px" }}>
          <Upload
            multiple
            accept=".kml,.kmz"
            listType="text"
            onChange={onKmlUpload}
          >
            <Button icon={<UploadOutlined />}>
              <span>
                <FormattedMessage
                  id="maps.uploadKmz"
                  defaultMessage="Upload KMZ/KML file"
                />
              </span>
            </Button>
          </Upload>

          <Tooltip
            title={
              <FormattedMessage
                id="maps.clear.help"
                defaultMessage="Clicking clear map will delete all polygons and map files from the map"
              />
            }
          >
            <Button icon={<DeleteOutlined />} onClick={clearMap}>
              <span>
                <FormattedMessage id="maps.clear" defaultMessage="Clear map" />
              </span>
            </Button>
          </Tooltip>
        </Space>
      )}

      <MapView
        {...viewState}
        height={300}
        {...props}
        defaultValue={defaultValue}
        onMove={(e) => setViewState(e.viewState)}
      >
        <DrawControl
          displayControlsDefault={false}
          controls={
            readonly
              ? undefined
              : {
                  point: !disablePoint,
                  polygon: !disablePolygon,
                  trash: true,
                }
          }
          setControl={assignDrawControl}
          onCreate={onDrawChange}
          onUpdate={onDrawChange}
          onDelete={onDrawDelete}
          position="top-left"
        />
      </MapView>
    </>
  );
}
