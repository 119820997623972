import { Row, Col, Typography, Divider } from "antd";
import { FormattedMessage } from "react-intl";
import {
  InventoryOrderDetailsFragment,
  FinanceOrderDetailsFragment,
  MakeOptional,
  TaxValueFragment,
} from "../../../lib/graphql";
import { useCurrentUser } from "../../../lib/hooks";
import {
  formatMoneyAmount,
  formatPercent,
  roundUnit,
} from "../../../lib/formats";
import { Form, Rules, InputNumber } from "../../form";
import { sumBy } from "lodash";

interface OrderSummaryProps {
  disabled?: boolean;
  order:
    | InventoryOrderDetailsFragment
    | MakeOptional<FinanceOrderDetailsFragment, "currencyCode">;
  taxValues: TaxValueFragment[];
  subtotal: number;
  otherCosts?: number;
}

export function OrderSummary({
  order,
  disabled,
  subtotal,
  otherCosts,
  taxValues = [],
}: OrderSummaryProps) {
  const { currentTenant } = useCurrentUser();
  const taxPlanEnabled = !!currentTenant.taxPlanEnabled;

  if (!order.currency || !order.exchangeRate) return null;

  const subtotalExchanged = subtotal * order.exchangeRate;

  const discount = disabled
    ? order.discount
    : order.discountRate
    ? (order.discountRate * subtotal) / 100.0
    : 0;

  const other = otherCosts || 0;
  const tax = disabled
    ? order.tax
    : taxPlanEnabled
    ? sumBy(taxValues, "value")
    : order.taxRate
    ? ((subtotal + other - discount) * order.taxRate) / 100.0
    : 0;

  const total = disabled
    ? order.total
    : roundUnit(subtotal + other - discount + tax);

  const orderCurrencyCode = order.currencyCode || currentTenant.currencyCode;

  return (
    <>
      <Row gutter={[0, 16]}>
        <Col span={8}></Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <FormattedMessage
            id="inventoryOrders.orderCurrency"
            defaultMessage="orderCurrency"
            values={{
              currency: orderCurrencyCode,
            }}
          />
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Typography.Text type="secondary">
            <FormattedMessage
              id="inventoryOrders.companyCurrency"
              defaultMessage="companyCurrency"
              values={{ currency: currentTenant.currencyCode }}
            />
          </Typography.Text>
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col span={8}>
          <strong>
            <FormattedMessage id="subtotal" />
          </strong>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          {formatMoneyAmount(subtotal, orderCurrencyCode)}
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Typography.Text type="secondary">
            {formatMoneyAmount(subtotalExchanged, currentTenant.currencyCode)}
          </Typography.Text>
        </Col>
      </Row>

      <Row align="middle" gutter={[16, 16]}>
        <Col span={6}>
          <strong>
            <FormattedMessage id="invoice.discount" defaultMessage="discount" />
          </strong>
        </Col>
        <Col span={4}>
          {disabled ? (
            formatPercent(order.discountRate)
          ) : (
            <Form.Item compact name="discountRate" rules={[Rules.percentage]}>
              <InputNumber
                size="small"
                addonAfter="%"
                min={0}
                max={99}
                style={{ width: "90px", marginLeft: "16px" }}
              />
            </Form.Item>
          )}
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <Typography.Text>
            {formatMoneyAmount(discount, orderCurrencyCode)}
          </Typography.Text>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Typography.Text type="secondary">
            {formatMoneyAmount(
              discount * order.exchangeRate,
              currentTenant.currencyCode
            )}
          </Typography.Text>
        </Col>
      </Row>

      {otherCosts != undefined && (
        <Row align="middle" justify="space-between" gutter={[16, 16]}>
          <Col span={12}>
            <strong>
              <FormattedMessage
                id="inventoryPurchaseOrders.other"
                defaultMessage="freight"
              />
            </strong>
          </Col>
          <Col span={4} style={{ textAlign: "right" }}>
            {disabled ? (
              formatMoneyAmount(other, orderCurrencyCode)
            ) : (
              <Form.Item compact name="otherCosts" rules={[Rules.gtEqZero]}>
                <InputNumber
                  size="small"
                  min={0}
                  addonBefore={order.currency?.symbol}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            )}
          </Col>
          <Col span={8} style={{ textAlign: "right" }}>
            <Typography.Text type="secondary">
              {formatMoneyAmount(
                other * order.exchangeRate,
                currentTenant.currencyCode
              )}
            </Typography.Text>
          </Col>
        </Row>
      )}

      {taxPlanEnabled &&
        taxValues.map((tv) => (
          <Row key={tv.component.id} justify="space-between" gutter={[16, 16]}>
            <Col span={8}>
              <Typography.Text strong>{tv.component.name}</Typography.Text>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              {formatMoneyAmount(tv.value, orderCurrencyCode)}
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Typography.Text type="secondary">
                {formatMoneyAmount(
                  (tv.value || 0) * order.exchangeRate,
                  currentTenant.currencyCode
                )}
              </Typography.Text>
            </Col>
          </Row>
        ))}

      {!taxPlanEnabled ? (
        <Row align="middle" gutter={[16, 16]}>
          <Col span={6}>
            <strong>
              <FormattedMessage id="vat" defaultMessage="VAT" />
            </strong>
          </Col>
          <Col span={4}>
            {disabled ? (
              formatPercent(order.taxRate)
            ) : (
              <Form.Item compact name="taxRate" rules={[Rules.percentage]}>
                <InputNumber
                  size="small"
                  addonAfter="%"
                  min={0}
                  max={100}
                  style={{ width: "90px", marginLeft: "16px" }}
                />
              </Form.Item>
            )}
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {formatMoneyAmount(tax, orderCurrencyCode)}
          </Col>
          <Col span={8} style={{ textAlign: "right" }}>
            <Typography.Text type="secondary">
              {formatMoneyAmount(
                tax * order.exchangeRate,
                currentTenant.currencyCode
              )}
            </Typography.Text>
          </Col>
        </Row>
      ) : null}

      <Divider />

      <Row align="middle" justify="space-between">
        <Col span={8}>
          <strong>
            <FormattedMessage id="grandTotal" defaultMessage="Grand Total" />
          </strong>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Typography.Text style={{ fontWeight: 500 }}>
            {formatMoneyAmount(total, orderCurrencyCode)}
          </Typography.Text>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Typography.Text type="secondary">
            {formatMoneyAmount(
              total * order.exchangeRate,
              currentTenant.currencyCode
            )}
          </Typography.Text>
        </Col>
      </Row>
    </>
  );
}
