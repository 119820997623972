import {
  useQuery,
  useLazyQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
} from "@apollo/client";
import { useEffectOnce } from "react-use";
import {
  LocalitiesDocument,
  LocalitiesQuery,
  LocalityUpdateDocument,
  LocalitiesQueryVariables,
  LocalityUpdateMutationVariables,
  LocalityUpdateMutation,
  LocalityCreateMutation,
  LocalityCreateMutationVariables,
  LocalityCreateDocument,
  LocalityDiscardMutation,
  LocalityDiscardMutationVariables,
  LocalityDiscardDocument,
  LocalityQuery,
  LocalityQueryVariables,
  LocalityDocument,
  LocalityDestroyDocument,
  LocalityDestroyMutation,
  LocalityDestroyMutationVariables,
} from "../../graphql";

export function useLocalityOptions(
  queryOptions?: QueryHookOptions<LocalitiesQuery, LocalitiesQueryVariables>,
  preload?: boolean
) {
  const [load, { loading, data }] = useLazyQuery(LocalitiesDocument, {
    variables: { pageSize: 100 },
    ...queryOptions,
  });

  useEffectOnce(() => {
    if (preload) load();
  });

  const options = (data?.localities?.items || [])
    .slice()
    .sort((a, b) => b.effectiveArea - a.effectiveArea)
    .map((l) => ({
      key: l.id,
      label: l.name,
      locality: l,
    }));

  return { load, loading, options };
}

export function useLocalities(
  options?: QueryHookOptions<LocalitiesQuery, LocalitiesQueryVariables>
) {
  const { loading, data, refetch } = useQuery(LocalitiesDocument, options);

  if (!data || !data.localities || !data.localities.items) {
    return { loading };
  }

  const items = data.localities.items;
  const totalCount = data.localities.totalCount;

  return { loading, items, totalCount, refetch };
}

export function useLocality(id?: string) {
  const skip = !id;

  const { data } = useQuery<LocalityQuery, LocalityQueryVariables>(
    LocalityDocument,
    {
      variables: { id: id || "" },
      // TODO: Workaround for a known bug in Apollo client that causes `skip` to be ignored
      // https://github.com/apollographql/react-apollo/issues/3492#issuecomment-622573677
      fetchPolicy: skip ? "cache-only" : "cache-first",
      skip,
    }
  );

  return data?.locality;
}

export function useLocalityUpdate(
  options?: MutationHookOptions<
    LocalityUpdateMutation,
    LocalityUpdateMutationVariables
  >
) {
  return useMutation(LocalityUpdateDocument, options);
}

export function useLocalityCreate(
  options?: MutationHookOptions<
    LocalityCreateMutation,
    LocalityCreateMutationVariables
  >
) {
  return useMutation(LocalityCreateDocument, options);
}

export function useLocalityDiscard(
  options?: MutationHookOptions<
    LocalityDiscardMutation,
    LocalityDiscardMutationVariables
  >
) {
  return useMutation(LocalityDiscardDocument, options);
}

export function useLocalityDestroy(
  options?: MutationHookOptions<
    LocalityDestroyMutation,
    LocalityDestroyMutationVariables
  >
) {
  return useMutation(LocalityDestroyDocument, options);
}
