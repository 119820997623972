import React, { ReactNode, useState } from "react";
import { Button, Switch } from "antd";
import { SaveMutationResult } from "../../../lib/hooks";
import { FormattedMessage } from "react-intl";
import { SidebarFooter } from "../Sidebar";
import Input, { InputProps, TextAreaProps } from "antd/lib/input";
import { FormItemProps, FormInstance } from "antd/lib/form";
import {
  Form,
  FormProps,
  SelectFieldProps,
  SelectField,
  DatePicker,
  SubmitButton,
  DatePickerProps,
  Cascader,
  InputNumberProps,
  InputNumber,
} from "../../form";
import { HelpTooltip } from "../../shared";
import { DateTimeFormInput } from "../../finance/DocumentDateFormInput";
import { filterFalse } from "../../../lib/utils";
import { formatEntityNameLabel } from "../../../lib/formats";

export interface ItemFieldFormConfig<TFormValues>
  extends Pick<
      SelectFieldProps,
      | "loading"
      | "mode"
      | "showGroups"
      | "onSearch"
      | "onFocus"
      | "options"
      | "newItemProps"
      | "className"
      | "placeholder"
      | "optionsHook"
      | "optionsHookParams"
      | "optionsHookFilter"
      | "formatOption"
      | "labelRenderer"
    >,
    Pick<FormItemProps, "rules"> {
  name?: Extract<keyof TFormValues, string>;
  key?: string | number | null;
  label?: React.ReactNode;
  type:
    | "string"
    | "select"
    | "cascader"
    | "number"
    | "date"
    | "datetime"
    | "text"
    | "switch"
    | "password"
    | "custom";
  render?({
    bag,
    setBag,
    form,
  }: {
    bag: Record<string, any>;
    setBag: (bag: Record<string, any>) => void;
    form: FormInstance;
  }): React.ReactNode;
  defaultValue?: any;
  prefix?: React.ReactNode;
  hidden?: boolean;
  disabled?: boolean;
  formItemProps?: Omit<FormItemProps, "children">;
  optional?: boolean;
  onChange?: (
    value: any,
    item: TFormValues,
    options: any[],
    form: FormInstance
  ) => void;
  numberProps?: InputNumberProps;
  inputProps?: Omit<InputProps & TextAreaProps, "name">;
  dateProps?: DatePickerProps;
  helpTooltipTitle?: ReactNode;
}

export interface ItemFormProps<T, TFormValues> {
  fields: Array<ItemFieldFormConfig<TFormValues> | false | null>;
  item?: T;
  initialValues: TFormValues;
  onCancel?: () => void;
  onSave(
    values: TFormValues,
    form: FormInstance
  ): Promise<SaveMutationResult<T> | null | undefined>;
  formProps?: FormProps<TFormValues>;
  initBag?: (item?: T) => Record<string, any>;
  saveText?: ReactNode;
  clearOnAction?: boolean;
  hideFooter?: boolean;
  entityName?: ReactNode;
}

export function ItemForm<T, TFormValues>(props: ItemFormProps<T, TFormValues>) {
  const [form] = Form.useForm();
  const values = props.initialValues;
  const [bag, setBag] = useState(
    (props.initBag && props.initBag(props.item)) || {}
  );

  const onCancel = () => {
    if (props.onCancel) props.onCancel();
    if (props.clearOnAction) form.resetFields();
  };

  return (
    <Form
      initialValues={values}
      layout="vertical"
      form={form}
      {...props.formProps}
      onSubmit={(values, { setSubmitting, form, showErrors }) => {
        props
          .onSave(values, form)
          .then((result) => {
            if (!result) return;

            setSubmitting(false);

            if (!result.result) {
              showErrors(result.errors);
            } else if (props.clearOnAction) {
              form.resetFields();
            }
          })
          .catch(() => setSubmitting(false));
      }}
    >
      {filterFalse(props.fields)
        .filter((f) => !f.hidden)
        .map((field: ItemFieldFormConfig<TFormValues>) => {
          if (!field.name && field.render) {
            return field.render({ bag, setBag, form });
          }

          let input: ReactNode | null = null;
          switch (field.type) {
            case "string":
              input = (
                <Input
                  name={field.name}
                  prefix={field.prefix}
                  disabled={field.disabled}
                  {...field.inputProps}
                />
              );
              break;
            case "text":
              input = (
                <Input.TextArea
                  name={field.name}
                  rows={4}
                  {...field.inputProps}
                />
              );
              break;
            case "number":
              input = (
                <InputNumber
                  name={field.name}
                  min={0}
                  style={{ width: "100%" }}
                  disabled={field.disabled}
                  onChange={(val) =>
                    field.onChange && field.onChange(val, values, [], form)
                  }
                  {...field.numberProps}
                />
              );
              break;
            case "date":
              input = (
                <DatePicker
                  name={field.name}
                  style={{ width: "100%" }}
                  disabled={field.disabled}
                  {...field.dateProps}
                />
              );
              break;
            case "datetime":
              input = (
                <DateTimeFormInput
                  name={field.name}
                  disabled={field.disabled}
                  dateProps={field.dateProps}
                />
              );
              break;
            case "switch":
              input = <Switch disabled={field.disabled} />;
              break;
            case "password":
              input = <Input.Password disabled={field.disabled} />;
              break;
            case "select": {
              const { allowClear, ...selectInputProps } =
                field.inputProps || {};

              input = (
                <SelectField
                  loading={field.loading}
                  mode={field.mode}
                  onSearch={field.onSearch}
                  onChange={(value, options) =>
                    field.onChange &&
                    field.onChange(value, values, options, form)
                  }
                  onFocus={field.onFocus}
                  options={field.options}
                  showGroups={field.showGroups}
                  placeholder={field.placeholder}
                  defaultValues={field.defaultValue}
                  newItemProps={field.newItemProps}
                  className={field.className}
                  disabled={field.disabled}
                  optionsHook={field.optionsHook}
                  optionsHookParams={field.optionsHookParams}
                  optionsHookFilter={field.optionsHookFilter}
                  formatOption={field.formatOption}
                  labelRenderer={field.labelRenderer}
                  allowClear={allowClear == null ? true : !!allowClear}
                  {...selectInputProps}
                />
              );
              break;
            }
            case "cascader":
              input = (
                <Cascader
                  options={field.options}
                  defaultValue={field.defaultValue}
                  disabled={field.disabled}
                />
              );
              break;
            case "custom":
              input = field.render ? field.render({ bag, setBag, form }) : null;
              break;
          }

          let label = field.label;

          if (!label && field.name === "name")
            label = formatEntityNameLabel(props.entityName);

          const item = input && (
            <Form.Item
              key={field.key || field.name}
              name={field.name}
              label={label}
              rules={field.rules}
              valuePropName={field.type === "switch" ? "checked" : undefined}
              {...field.formItemProps}
            >
              {input}
            </Form.Item>
          );

          if (field.helpTooltipTitle) {
            return (
              <HelpTooltip
                key={field.key || field.name}
                title={field.helpTooltipTitle}
              >
                {item}
              </HelpTooltip>
            );
          }

          return item;
        })}

      {!props.hideFooter && (
        <>
          <div style={{ height: "46px" }} />
          <SidebarFooter>
            <Button onClick={onCancel} style={{ marginRight: "8px" }}>
              <FormattedMessage id="cancel" defaultMessage="cancel" />
            </Button>
            <SubmitButton type="primary">
              <span>
                {props.saveText || (
                  <FormattedMessage id="save" defaultMessage="save" />
                )}
              </span>
            </SubmitButton>
          </SidebarFooter>
        </>
      )}
    </Form>
  );
}
